// @flow

import type { AssetGroup, AssetGroupMembershipList } from './asset_groups';

import type { PolicyRuleQuery_policy_by_id_policy_rules_configuration } from '@dt/graphql-support/types/PolicyRuleQuery';

export const HTTPMethodEnum = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
  OPTIONS: 'OPTIONS',
  HEAD: 'HEAD',
  PATCH: 'PATCH',
  TRACE: 'TRACE',
  UNKNOWN: 'X_HORIZON_UNDEFINED_HTTP_METHOD',
  X_HORIZON_UNDEFINED_HTTP_METHOD: 'X_HORIZON_UNDEFINED_HTTP_METHOD',
};

export type HTTPMethod = $Keys<typeof HTTPMethodEnum>;

export type ApplicationLayerProtocol = 'UNKNOWN' | 'HTTP';

export const ConnectivityScanStatus = {
  REACHABLE: 'Online',
  NOT_REACHABLE: 'Not reachable',
  NOT_REACHABLE_CONNECTION_REJECTED: 'Not reachable',
  NOT_REACHABLE_CONNECTION_TIMED_OUT: 'Not reachable',
  NOT_REACHABLE_DNS_LOOKUP_FAILED: 'Not reachable',
};
export type ConnectivityScanStatusType = $Keys<typeof ConnectivityScanStatus>;

export type ConnectivityScanResult = {
  status: ConnectivityScanStatusType,
  date_created: string,
  ...
};
export type ConnectivityScanResultList = $ReadOnlyArray<ConnectivityScanResult>;

export const DiscoveredVia = {
  MANUAL_IMPORT: 'MANUAL_IMPORT',
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES',
  MOBILE_APP_SCANS: 'MOBILE_APP_SCANS',
  MULESOFT: 'MULESOFT',
  APIGEE: 'APIGEE',
  CERTIFICATE_TRANSPARENCY: 'CERTIFICATE_TRANSPARENCY',
  AZURE: 'AZURE',
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM',
  WEB_APPLICATION_SCANS: 'WEB_APPLICATION_SCANS',
  REVERSE_IP_LOOKUP: 'REVERSE_IP_LOOKUP',
  KONG: 'KONG',
  SAN_EXTRACTION: 'SAN_EXTRACTION',
  APIGEE_ON_PREM: 'APIGEE_ON_PREM',
  RESTFUL_API_DISCOVERER: 'RESTFUL_API_DISCOVERER',
  WEBSITE_CRAWLER: 'WEBSITE_CRAWLER',
  APPLICATION_FINGERPRINTING: 'APPLICATION_FINGERPRINTING',
  AXWAY: 'AXWAY',
  SWAGGER_HUB: 'SWAGGER_HUB',
  MOBILE_APP_STORE: 'MOBILE_APP_STORE',
  MOBILE_PROTECT_USAGE: 'MOBILE_PROTECT_USAGE',
  CLOUD_PROTECT: 'CLOUD_PROTECT',
  KUBERNETES_CLUSTER_ANALYSIS: 'KUBERNETES_CLUSTER_ANALYSIS',
  GITHUB_INTEGRATION: 'GITHUB_INTEGRATION',
  BITBUCKET_INTEGRATION: 'BITBUCKET_INTEGRATION',
  GITHUB_ENTERPRISE_SERVER_INTEGRATION: 'GITHUB_ENTERPRISE_SERVER_INTEGRATION',
  GITLAB_INTEGRATION: 'GITLAB_INTEGRATION',
  POSTMAN_CLOUD: 'POSTMAN_CLOUD',
  CLOUDFLARE: 'CLOUDFLARE',
};
export type DiscoveredViaType = $Keys<typeof DiscoveredVia>;

export type TLSScanAttributes = {
  enum: string,
  value: boolean,
  opened_policy_violation_id?: string,
  resolved_policy_violation_id?: string,
  ...
};

export type TLSScanResult = {
  network_service_id: string,
  tls_scan_attributes: $ReadOnlyArray<TLSScanAttributes>,
  ...
};

export type TlsScanResultList = $ReadOnlyArray<TLSScanResult>;

/** Network Service */

export const HostedOnEnum = {
  DETECTION_FAILED: 'DETECTION_FAILED',
  APIGEE: 'APIGEE',
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES',
  AZURE: 'AZURE',
  KONG: 'KONG',
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM',
  MULESOFT: 'MULESOFT',
  AXWAY: 'AXWAY',
  ON_PREMISE: 'ON_PREMISE',
  UNKNOWN: 'UNKNOWN',
  GITHUB: 'GITHUB',
  BITBUCKET: 'BITBUCKET',
  GITHUB_ENTERPRISE_SERVER: 'GITHUB_ENTERPRISE_SERVER',
  CLOUDFLARE: 'CLOUDFLARE',
};
export type HostedOn = $Keys<typeof HostedOnEnum>;

export type NetworkService = {|
  id: string,
  domain_name_id: string,
  port: number,
  url: string,
  application_layer_protocol: ApplicationLayerProtocol,
  is_tls_encrypted: boolean,
  asset_type_name: string,
  asset_type_icon_url: string,

  hosted_on: HostedOn,
  connectivity_scan_results: ConnectivityScanResultList,

  discovered_via: DiscoveredViaType,
  discovered_via_apigee_authenticator_id: ?string,
  discovered_via_aws_authenticator_id: ?string,
  discovered_via_azure_authenticator_id: ?string,
  discovered_via_axway_authenticator_id: ?string,
  discovered_via_gcp_authenticator_id: ?string,
  discovered_via_mulesoft_authenticator_id: ?string,
  discovered_via_kong_authenticator_id: null,
  discovered_via_user_id: ?string,

  date_created: string,
  policy_violation_ids: $ReadOnlyArray<string>,

  asset_group_memberships?: AssetGroupMembershipList,
|};
export type NetworkServicesList = $ReadOnlyArray<NetworkService>;

/** Policy Violation */

export type Policy = {|
  +id: string,
  +name: string,
  +created_by_user_id: string,
  +is_special_dt_policy: boolean,
  +date_created: string,
  +enabled_policy_rules_count: number,
  +protected_asset_groups_count: number,
  +description: string,
|};

export type PolicyList = $ReadOnlyArray<Policy>;

export type PolicyDecorated = {|
  ...Policy,
  created_by_user: void | null | User,
|};
export type PolicyDecoratedList = $ReadOnlyArray<PolicyDecorated>;

export type AssetGroupDecorated = {|
  ...AssetGroup,
  created_by_user: void | null | User,
  attached_policy: void | null | PolicyDecorated,
|};

export const PolicyRuleTypeRelevance = {
  PROACTIVE: 'PROACTIVE',
  IMPORTANT: 'IMPORTANT',
  URGENT: 'URGENT',
};
export type PolicyRuleTypeRelevanceType = $Keys<typeof PolicyRuleTypeRelevance>;

export const PolicyComplianceStandards = {
  OWASP: ('OWASP': 'OWASP'),
  PCI_DSS: ('PCI_DSS': 'PCI_DSS'),
  NIST_800_53: ('NIST_800_53': 'NIST_800_53'),
  FFIEC_VC2: ('FFIEC_VC2': 'FFIEC_VC2'),
  CIS_BENCHMARK: ('CIS_BENCHMARK': 'CIS_BENCHMARK'),
};
export type PolicyComplianceStandardType = $Keys<typeof PolicyComplianceStandards>;

export const PolicyComplianceStandardCriteria = {
  // OWASP API Top 10 2019
  OWASP_A1_2019: ('OWASP_A1_2019': 'OWASP_A1_2019'),
  OWASP_A2_2019: ('OWASP_A2_2019': 'OWASP_A2_2019'),
  OWASP_A3_2019: ('OWASP_A3_2019': 'OWASP_A3_2019'),
  OWASP_A4_2019: ('OWASP_A4_2019': 'OWASP_A4_2019'),
  OWASP_A5_2019: ('OWASP_A5_2019': 'OWASP_A5_2019'),
  OWASP_A6_2019: ('OWASP_A6_2019': 'OWASP_A6_2019'),
  OWASP_A7_2019: ('OWASP_A7_2019': 'OWASP_A7_2019'),
  OWASP_A8_2019: ('OWASP_A8_2019': 'OWASP_A8_2019'),
  OWASP_A9_2019: ('OWASP_A9_2019': 'OWASP_A9_2019'),
  OWASP_A10_2019: ('OWASP_A10_2019': 'OWASP_A10_2019'),
  // OWASP WEB Top 10 2021
  OWASP_W1_2021: ('OWASP_W1_2021': 'OWASP_W1_2021'),
  OWASP_W2_2021: ('OWASP_W2_2021': 'OWASP_W2_2021'),
  OWASP_W3_2021: ('OWASP_W3_2021': 'OWASP_W3_2021'),
  OWASP_W4_2021: ('OWASP_W4_2021': 'OWASP_W4_2021'),
  OWASP_W5_2021: ('OWASP_W5_2021': 'OWASP_W5_2021'),
  OWASP_W6_2021: ('OWASP_W6_2021': 'OWASP_W6_2021'),
  OWASP_W7_2021: ('OWASP_W7_2021': 'OWASP_W7_2021'),
  OWASP_W8_2021: ('OWASP_W8_2021': 'OWASP_W8_2021'),
  OWASP_W9_2021: ('OWASP_W9_2021': 'OWASP_W9_2021'),
  OWASP_W10_2021: ('OWASP_W10_2021': 'OWASP_W10_2021'),
  // OWASP Cloud Risks 2011
  OWASP_R1_2011: ('OWASP_R1_2011': 'OWASP_R1_2011'),
  OWASP_R2_2011: ('OWASP_R2_2011': 'OWASP_R2_2011'),
  OWASP_R3_2011: ('OWASP_R3_2011': 'OWASP_R3_2011'),
  OWASP_R4_2011: ('OWASP_R4_2011': 'OWASP_R4_2011'),
  OWASP_R5_2011: ('OWASP_R5_2011': 'OWASP_R5_2011'),
  OWASP_R6_2011: ('OWASP_R6_2011': 'OWASP_R6_2011'),
  OWASP_R7_2011: ('OWASP_R7_2011': 'OWASP_R7_2011'),
  OWASP_R8_2011: ('OWASP_R8_2011': 'OWASP_R8_2011'),
  OWASP_R9_2011: ('OWASP_R9_2011': 'OWASP_R9_2011'),
  OWASP_R10_2011: ('OWASP_R10_2011': 'OWASP_R10_2011'),
  // PCI DSS v 3.2.1
  PCI_DSS_v3_2_1_a1_3: ('PCI_DSS_v3_2_1_a1_3': 'PCI_DSS_v3_2_1_a1_3'), // PCI DSS v3.2.1 standard 1.3
  PCI_DSS_v3_2_1_a1_2_1: ('PCI_DSS_v3_2_1_a1_2_1': 'PCI_DSS_v3_2_1_a1_2_1'), // PCI DSS v3.2.1 standard 1.2.1
  PCI_DSS_v3_2_1_a2_3: ('PCI_DSS_v3_2_1_a2_3': 'PCI_DSS_v3_2_1_a2_3'), // PCI DSS v3.2.1 standard 2.3
  PCI_DSS_v3_2_1_a2_2_3: ('PCI_DSS_v3_2_1_a2_2_3': 'PCI_DSS_v3_2_1_a2_2_3'), // PCI DSS v3.2.1 standard 2.2.3
  PCI_DSS_v3_2_1_a3_4: ('PCI_DSS_v3_2_1_a3_4': 'PCI_DSS_v3_2_1_a3_4'), // PCI DSS v3.2.1 standard 3.4
  PCI_DSS_v3_2_1_a4_1: ('PCI_DSS_v3_2_1_a4_1': 'PCI_DSS_v3_2_1_a4_1'), // PCI DSS v3.2.1 standard 4.1
  PCI_DSS_v3_2_1_a6_1: ('PCI_DSS_v3_2_1_a6_1': 'PCI_DSS_v3_2_1_a6_1'), // PCI DSS v3.2.1 standard 6.1
  PCI_DSS_v3_2_1_a6_5_10: ('PCI_DSS_v3_2_1_a6_5_10': 'PCI_DSS_v3_2_1_a6_5_10'), // PCI DSS v3.2.1 standard 6.5.10
  PCI_DSS_v3_2_1_a6_6: ('PCI_DSS_v3_2_1_a6_6': 'PCI_DSS_v3_2_1_a6_6'), // PCI DSS v3.2.1 standard 6.6
  PCI_DSS_v3_2_1_a8_7: ('PCI_DSS_v3_2_1_a8_7': 'PCI_DSS_v3_2_1_a8_7'), // PCI DSS v3.2.1 standard 8.7
  PCI_DSS_v3_2_1_a10_1: ('PCI_DSS_v3_2_1_a10_1': 'PCI_DSS_v3_2_1_a10_1'), // PCI DSS v3.2.1 standard 10.1
  PCI_DSS_v3_2_1_aA_2_1: ('PCI_DSS_v3_2_1_aA_2_1': 'PCI_DSS_v3_2_1_aA_2_1'), // PCI DSS v3.2.1 standard A2.1
  // NIST_800_53
  NIST_800_53_AC_1: ('NIST_800_53_AC_1': 'NIST_800_53_AC_1'),
  NIST_800_53_AC_4: ('NIST_800_53_AC_4': 'NIST_800_53_AC_4'),
  NIST_800_53_AU_11: ('NIST_800_53_AU_11': 'NIST_800_53_AU_11'),
  NIST_800_53_SA_2: ('NIST_800_53_SA_2': 'NIST_800_53_SA_2'),
  NIST_800_53_SA_4: ('NIST_800_53_SA_4': 'NIST_800_53_SA_4'),
  NIST_800_53_SC_1: ('NIST_800_53_SC_1': 'NIST_800_53_SC_1'),
  NIST_800_53_SC_7: ('NIST_800_53_SC_7': 'NIST_800_53_SC_7'),
  NIST_800_53_SC_12: ('NIST_800_53_SC_12': 'NIST_800_53_SC_12'),
  NIST_800_53_SC_16: ('NIST_800_53_SC_16': 'NIST_800_53_SC_16'),
  NIST_800_53_SI_1: ('NIST_800_53_SI_1': 'NIST_800_53_SI_1'),
  NIST_800_53_SI_2: ('NIST_800_53_SI_2': 'NIST_800_53_SI_2'),
  NIST_800_53_CM_2: ('NIST_800_53_CM_2': 'NIST_800_53_CM_2'),
  NIST_800_53_CM_4: ('NIST_800_53_CM_4': 'NIST_800_53_CM_4'),
  // OWASP M* 2014
  OWASP_M1_2014: ('OWASP_M1_2014': 'OWASP_M1_2014'),
  OWASP_M2_2014: ('OWASP_M2_2014': 'OWASP_M2_2014'),
  OWASP_M3_2014: ('OWASP_M3_2014': 'OWASP_M3_2014'),
  OWASP_M4_2014: ('OWASP_M4_2014': 'OWASP_M4_2014'),
  OWASP_M5_2014: ('OWASP_M5_2014': 'OWASP_M5_2014'),
  OWASP_M6_2014: ('OWASP_M6_2014': 'OWASP_M6_2014'),
  OWASP_M7_2014: ('OWASP_M7_2014': 'OWASP_M7_2014'),
  OWASP_M8_2014: ('OWASP_M8_2014': 'OWASP_M8_2014'),
  OWASP_M9_2014: ('OWASP_M9_2014': 'OWASP_M9_2014'),
  OWASP_M10_2014: ('OWASP_M10_2014': 'OWASP_M10_2014'),
  // OWASP M* 2016
  OWASP_M1_2016: ('OWASP_M1_2016': 'OWASP_M1_2016'),
  OWASP_M2_2016: ('OWASP_M2_2016': 'OWASP_M2_2016'),
  OWASP_M3_2016: ('OWASP_M3_2016': 'OWASP_M3_2016'),
  OWASP_M4_2016: ('OWASP_M4_2016': 'OWASP_M4_2016'),
  OWASP_M5_2016: ('OWASP_M5_2016': 'OWASP_M5_2016'),
  OWASP_M6_2016: ('OWASP_M6_2016': 'OWASP_M6_2016'),
  OWASP_M7_2016: ('OWASP_M7_2016': 'OWASP_M7_2016'),
  OWASP_M8_2016: ('OWASP_M8_2016': 'OWASP_M8_2016'),
  OWASP_M9_2016: ('OWASP_M9_2016': 'OWASP_M9_2016'),
  OWASP_M10_2016: ('OWASP_M10_2016': 'OWASP_M10_2016'),
  // OWASP MASTG
  OWASP_MSTG_ARCH_1: ('OWASP_MSTG_ARCH_1': 'OWASP_MSTG_ARCH_1'),
  OWASP_MSTG_ARCH_2: ('OWASP_MSTG_ARCH_2': 'OWASP_MSTG_ARCH_2'),
  OWASP_MSTG_ARCH_3: ('OWASP_MSTG_ARCH_3': 'OWASP_MSTG_ARCH_3'),
  OWASP_MSTG_ARCH_4: ('OWASP_MSTG_ARCH_4': 'OWASP_MSTG_ARCH_4'),
  OWASP_MSTG_ARCH_5: ('OWASP_MSTG_ARCH_5': 'OWASP_MSTG_ARCH_5'),
  OWASP_MSTG_ARCH_6: ('OWASP_MSTG_ARCH_6': 'OWASP_MSTG_ARCH_6'),
  OWASP_MSTG_ARCH_7: ('OWASP_MSTG_ARCH_7': 'OWASP_MSTG_ARCH_7'),
  OWASP_MSTG_ARCH_8: ('OWASP_MSTG_ARCH_8': 'OWASP_MSTG_ARCH_8'),
  OWASP_MSTG_ARCH_9: ('OWASP_MSTG_ARCH_9': 'OWASP_MSTG_ARCH_9'),
  OWASP_MSTG_ARCH_10: ('OWASP_MSTG_ARCH_10': 'OWASP_MSTG_ARCH_10'),
  OWASP_MSTG_ARCH_11: ('OWASP_MSTG_ARCH_11': 'OWASP_MSTG_ARCH_11'),
  OWASP_MSTG_ARCH_12: ('OWASP_MSTG_ARCH_12': 'OWASP_MSTG_ARCH_12'),
  OWASP_MSTG_STORAGE_1: ('OWASP_MSTG_STORAGE_1': 'OWASP_MSTG_STORAGE_1'),
  OWASP_MSTG_STORAGE_2: ('OWASP_MSTG_STORAGE_2': 'OWASP_MSTG_STORAGE_2'),
  OWASP_MSTG_STORAGE_3: ('OWASP_MSTG_STORAGE_3': 'OWASP_MSTG_STORAGE_3'),
  OWASP_MSTG_STORAGE_4: ('OWASP_MSTG_STORAGE_4': 'OWASP_MSTG_STORAGE_4'),
  OWASP_MSTG_STORAGE_5: ('OWASP_MSTG_STORAGE_5': 'OWASP_MSTG_STORAGE_5'),
  OWASP_MSTG_STORAGE_6: ('OWASP_MSTG_STORAGE_6': 'OWASP_MSTG_STORAGE_6'),
  OWASP_MSTG_STORAGE_7: ('OWASP_MSTG_STORAGE_7': 'OWASP_MSTG_STORAGE_7'),
  OWASP_MSTG_STORAGE_8: ('OWASP_MSTG_STORAGE_8': 'OWASP_MSTG_STORAGE_8'),
  OWASP_MSTG_STORAGE_9: ('OWASP_MSTG_STORAGE_9': 'OWASP_MSTG_STORAGE_9'),
  OWASP_MSTG_STORAGE_10: ('OWASP_MSTG_STORAGE_10': 'OWASP_MSTG_STORAGE_10'),
  OWASP_MSTG_STORAGE_11: ('OWASP_MSTG_STORAGE_11': 'OWASP_MSTG_STORAGE_11'),
  OWASP_MSTG_STORAGE_12: ('OWASP_MSTG_STORAGE_12': 'OWASP_MSTG_STORAGE_12'),
  OWASP_MSTG_STORAGE_13: ('OWASP_MSTG_STORAGE_13': 'OWASP_MSTG_STORAGE_13'),
  OWASP_MSTG_STORAGE_14: ('OWASP_MSTG_STORAGE_14': 'OWASP_MSTG_STORAGE_14'),
  OWASP_MSTG_STORAGE_15: ('OWASP_MSTG_STORAGE_15': 'OWASP_MSTG_STORAGE_15'),
  OWASP_MSTG_CRYPTO_1: ('OWASP_MSTG_CRYPTO_1': 'OWASP_MSTG_CRYPTO_1'),
  OWASP_MSTG_CRYPTO_2: ('OWASP_MSTG_CRYPTO_2': 'OWASP_MSTG_CRYPTO_2'),
  OWASP_MSTG_CRYPTO_3: ('OWASP_MSTG_CRYPTO_3': 'OWASP_MSTG_CRYPTO_3'),
  OWASP_MSTG_CRYPTO_4: ('OWASP_MSTG_CRYPTO_4': 'OWASP_MSTG_CRYPTO_4'),
  OWASP_MSTG_CRYPTO_5: ('OWASP_MSTG_CRYPTO_5': 'OWASP_MSTG_CRYPTO_5'),
  OWASP_MSTG_CRYPTO_6: ('OWASP_MSTG_CRYPTO_6': 'OWASP_MSTG_CRYPTO_6'),
  OWASP_MSTG_AUTH_1: ('OWASP_MSTG_AUTH_1': 'OWASP_MSTG_AUTH_1'),
  OWASP_MSTG_AUTH_2: ('OWASP_MSTG_AUTH_2': 'OWASP_MSTG_AUTH_2'),
  OWASP_MSTG_AUTH_3: ('OWASP_MSTG_AUTH_3': 'OWASP_MSTG_AUTH_3'),
  OWASP_MSTG_AUTH_4: ('OWASP_MSTG_AUTH_4': 'OWASP_MSTG_AUTH_4'),
  OWASP_MSTG_AUTH_5: ('OWASP_MSTG_AUTH_5': 'OWASP_MSTG_AUTH_5'),
  OWASP_MSTG_AUTH_6: ('OWASP_MSTG_AUTH_6': 'OWASP_MSTG_AUTH_6'),
  OWASP_MSTG_AUTH_7: ('OWASP_MSTG_AUTH_7': 'OWASP_MSTG_AUTH_7'),
  OWASP_MSTG_AUTH_8: ('OWASP_MSTG_AUTH_8': 'OWASP_MSTG_AUTH_8'),
  OWASP_MSTG_AUTH_9: ('OWASP_MSTG_AUTH_9': 'OWASP_MSTG_AUTH_9'),
  OWASP_MSTG_AUTH_10: ('OWASP_MSTG_AUTH_10': 'OWASP_MSTG_AUTH_10'),
  OWASP_MSTG_AUTH_11: ('OWASP_MSTG_AUTH_11': 'OWASP_MSTG_AUTH_11'),
  OWASP_MSTG_AUTH_12: ('OWASP_MSTG_AUTH_12': 'OWASP_MSTG_AUTH_12'),
  OWASP_MSTG_NETWORK_1: ('OWASP_MSTG_NETWORK_1': 'OWASP_MSTG_NETWORK_1'),
  OWASP_MSTG_NETWORK_2: ('OWASP_MSTG_NETWORK_2': 'OWASP_MSTG_NETWORK_2'),
  OWASP_MSTG_NETWORK_3: ('OWASP_MSTG_NETWORK_3': 'OWASP_MSTG_NETWORK_3'),
  OWASP_MSTG_NETWORK_4: ('OWASP_MSTG_NETWORK_4': 'OWASP_MSTG_NETWORK_4'),
  OWASP_MSTG_NETWORK_5: ('OWASP_MSTG_NETWORK_5': 'OWASP_MSTG_NETWORK_5'),
  OWASP_MSTG_NETWORK_6: ('OWASP_MSTG_NETWORK_6': 'OWASP_MSTG_NETWORK_6'),
  OWASP_MSTG_PLATFORM_1: ('OWASP_MSTG_PLATFORM_1': 'OWASP_MSTG_PLATFORM_1'),
  OWASP_MSTG_PLATFORM_2: ('OWASP_MSTG_PLATFORM_2': 'OWASP_MSTG_PLATFORM_2'),
  OWASP_MSTG_PLATFORM_3: ('OWASP_MSTG_PLATFORM_3': 'OWASP_MSTG_PLATFORM_3'),
  OWASP_MSTG_PLATFORM_4: ('OWASP_MSTG_PLATFORM_4': 'OWASP_MSTG_PLATFORM_4'),
  OWASP_MSTG_PLATFORM_5: ('OWASP_MSTG_PLATFORM_5': 'OWASP_MSTG_PLATFORM_5'),
  OWASP_MSTG_PLATFORM_6: ('OWASP_MSTG_PLATFORM_6': 'OWASP_MSTG_PLATFORM_6'),
  OWASP_MSTG_PLATFORM_7: ('OWASP_MSTG_PLATFORM_7': 'OWASP_MSTG_PLATFORM_7'),
  OWASP_MSTG_PLATFORM_8: ('OWASP_MSTG_PLATFORM_8': 'OWASP_MSTG_PLATFORM_8'),
  OWASP_MSTG_PLATFORM_9: ('OWASP_MSTG_PLATFORM_9': 'OWASP_MSTG_PLATFORM_9'),
  OWASP_MSTG_PLATFORM_10: ('OWASP_MSTG_PLATFORM_10': 'OWASP_MSTG_PLATFORM_10'),
  OWASP_MSTG_PLATFORM_11: ('OWASP_MSTG_PLATFORM_11': 'OWASP_MSTG_PLATFORM_11'),
  OWASP_MSTG_CODE_1: ('OWASP_MSTG_CODE_1': 'OWASP_MSTG_CODE_1'),
  OWASP_MSTG_CODE_2: ('OWASP_MSTG_CODE_2': 'OWASP_MSTG_CODE_2'),
  OWASP_MSTG_CODE_3: ('OWASP_MSTG_CODE_3': 'OWASP_MSTG_CODE_3'),
  OWASP_MSTG_CODE_4: ('OWASP_MSTG_CODE_4': 'OWASP_MSTG_CODE_4'),
  OWASP_MSTG_CODE_5: ('OWASP_MSTG_CODE_5': 'OWASP_MSTG_CODE_5'),
  OWASP_MSTG_CODE_6: ('OWASP_MSTG_CODE_6': 'OWASP_MSTG_CODE_6'),
  OWASP_MSTG_CODE_7: ('OWASP_MSTG_CODE_7': 'OWASP_MSTG_CODE_7'),
  OWASP_MSTG_CODE_8: ('OWASP_MSTG_CODE_8': 'OWASP_MSTG_CODE_8'),
  OWASP_MSTG_CODE_9: ('OWASP_MSTG_CODE_9': 'OWASP_MSTG_CODE_9'),
  OWASP_MSTG_RESILIENCE_1: ('OWASP_MSTG_RESILIENCE_1': 'OWASP_MSTG_RESILIENCE_1'),
  OWASP_MSTG_RESILIENCE_2: ('OWASP_MSTG_RESILIENCE_2': 'OWASP_MSTG_RESILIENCE_2'),
  OWASP_MSTG_RESILIENCE_3: ('OWASP_MSTG_RESILIENCE_3': 'OWASP_MSTG_RESILIENCE_3'),
  OWASP_MSTG_RESILIENCE_4: ('OWASP_MSTG_RESILIENCE_4': 'OWASP_MSTG_RESILIENCE_4'),
  OWASP_MSTG_RESILIENCE_5: ('OWASP_MSTG_RESILIENCE_5': 'OWASP_MSTG_RESILIENCE_5'),
  OWASP_MSTG_RESILIENCE_6: ('OWASP_MSTG_RESILIENCE_6': 'OWASP_MSTG_RESILIENCE_6'),
  OWASP_MSTG_RESILIENCE_7: ('OWASP_MSTG_RESILIENCE_7': 'OWASP_MSTG_RESILIENCE_7'),
  OWASP_MSTG_RESILIENCE_8: ('OWASP_MSTG_RESILIENCE_8': 'OWASP_MSTG_RESILIENCE_8'),
  OWASP_MSTG_RESILIENCE_9: ('OWASP_MSTG_RESILIENCE_9': 'OWASP_MSTG_RESILIENCE_9'),
  OWASP_MSTG_RESILIENCE_10: ('OWASP_MSTG_RESILIENCE_10': 'OWASP_MSTG_RESILIENCE_10'),
  OWASP_MSTG_RESILIENCE_11: ('OWASP_MSTG_RESILIENCE_11': 'OWASP_MSTG_RESILIENCE_11'),
  OWASP_MSTG_RESILIENCE_12: ('OWASP_MSTG_RESILIENCE_12': 'OWASP_MSTG_RESILIENCE_12'),
  OWASP_MSTG_RESILIENCE_13: ('OWASP_MSTG_RESILIENCE_13': 'OWASP_MSTG_RESILIENCE_13'),
  // OWASP ASVS
  OWASP_ASVS_V_1_1: ('OWASP_ASVS_V_1_1': 'OWASP_ASVS_V_1_1'),
  OWASP_ASVS_V_1_2: ('OWASP_ASVS_V_1_2': 'OWASP_ASVS_V_1_2'),
  OWASP_ASVS_V_1_3: ('OWASP_ASVS_V_1_3': 'OWASP_ASVS_V_1_3'),
  OWASP_ASVS_V_1_4: ('OWASP_ASVS_V_1_4': 'OWASP_ASVS_V_1_4'),
  OWASP_ASVS_V_1_5: ('OWASP_ASVS_V_1_5': 'OWASP_ASVS_V_1_5'),
  OWASP_ASVS_V_1_6: ('OWASP_ASVS_V_1_6': 'OWASP_ASVS_V_1_6'),
  OWASP_ASVS_V_1_7: ('OWASP_ASVS_V_1_7': 'OWASP_ASVS_V_1_7'),
  OWASP_ASVS_V_1_8: ('OWASP_ASVS_V_1_8': 'OWASP_ASVS_V_1_8'),
  OWASP_ASVS_V_1_9: ('OWASP_ASVS_V_1_9': 'OWASP_ASVS_V_1_9'),
  OWASP_ASVS_V_1_10: ('OWASP_ASVS_V_1_10': 'OWASP_ASVS_V_1_10'),
  OWASP_ASVS_V_1_11: ('OWASP_ASVS_V_1_11': 'OWASP_ASVS_V_1_11'),
  OWASP_ASVS_V_1_12: ('OWASP_ASVS_V_1_12': 'OWASP_ASVS_V_1_12'),
  OWASP_ASVS_V_1_13: ('OWASP_ASVS_V_1_13': 'OWASP_ASVS_V_1_13'),
  OWASP_ASVS_V_1_14: ('OWASP_ASVS_V_1_14': 'OWASP_ASVS_V_1_14'),
  OWASP_ASVS_V_2_1: ('OWASP_ASVS_V_2_1': 'OWASP_ASVS_V_2_1'),
  OWASP_ASVS_V_2_2: ('OWASP_ASVS_V_2_2': 'OWASP_ASVS_V_2_2'),
  OWASP_ASVS_V_2_3: ('OWASP_ASVS_V_2_3': 'OWASP_ASVS_V_2_3'),
  OWASP_ASVS_V_2_4: ('OWASP_ASVS_V_2_4': 'OWASP_ASVS_V_2_4'),
  OWASP_ASVS_V_2_5: ('OWASP_ASVS_V_2_5': 'OWASP_ASVS_V_2_5'),
  OWASP_ASVS_V_2_6: ('OWASP_ASVS_V_2_6': 'OWASP_ASVS_V_2_6'),
  OWASP_ASVS_V_2_7: ('OWASP_ASVS_V_2_7': 'OWASP_ASVS_V_2_7'),
  OWASP_ASVS_V_2_8: ('OWASP_ASVS_V_2_8': 'OWASP_ASVS_V_2_8'),
  OWASP_ASVS_V_2_9: ('OWASP_ASVS_V_2_9': 'OWASP_ASVS_V_2_9'),
  OWASP_ASVS_V_2_10: ('OWASP_ASVS_V_2_10': 'OWASP_ASVS_V_2_10'),
  OWASP_ASVS_V_3_1: ('OWASP_ASVS_V_3_1': 'OWASP_ASVS_V_3_1'),
  OWASP_ASVS_V_3_2: ('OWASP_ASVS_V_3_2': 'OWASP_ASVS_V_3_2'),
  OWASP_ASVS_V_3_3: ('OWASP_ASVS_V_3_3': 'OWASP_ASVS_V_3_3'),
  OWASP_ASVS_V_3_4: ('OWASP_ASVS_V_3_4': 'OWASP_ASVS_V_3_4'),
  OWASP_ASVS_V_3_5: ('OWASP_ASVS_V_3_5': 'OWASP_ASVS_V_3_5'),
  OWASP_ASVS_V_3_6: ('OWASP_ASVS_V_3_6': 'OWASP_ASVS_V_3_6'),
  OWASP_ASVS_V_3_7: ('OWASP_ASVS_V_3_7': 'OWASP_ASVS_V_3_7'),
  OWASP_ASVS_V_3_8: ('OWASP_ASVS_V_3_8': 'OWASP_ASVS_V_3_8'),
  OWASP_ASVS_V_3_9: ('OWASP_ASVS_V_3_9': 'OWASP_ASVS_V_3_9'),
  OWASP_ASVS_V_3_10: ('OWASP_ASVS_V_3_10': 'OWASP_ASVS_V_3_10'),
  OWASP_ASVS_V_4_1: ('OWASP_ASVS_V_4_1': 'OWASP_ASVS_V_4_1'),
  OWASP_ASVS_V_4_2: ('OWASP_ASVS_V_4_2': 'OWASP_ASVS_V_4_2'),
  OWASP_ASVS_V_4_3: ('OWASP_ASVS_V_4_3': 'OWASP_ASVS_V_4_3'),
  OWASP_ASVS_V_4_4: ('OWASP_ASVS_V_4_4': 'OWASP_ASVS_V_4_4'),
  OWASP_ASVS_V_4_5: ('OWASP_ASVS_V_4_5': 'OWASP_ASVS_V_4_5'),
  OWASP_ASVS_V_4_6: ('OWASP_ASVS_V_4_6': 'OWASP_ASVS_V_4_6'),
  OWASP_ASVS_V_4_7: ('OWASP_ASVS_V_4_7': 'OWASP_ASVS_V_4_7'),
  OWASP_ASVS_V_4_8: ('OWASP_ASVS_V_4_8': 'OWASP_ASVS_V_4_8'),
  OWASP_ASVS_V_4_9: ('OWASP_ASVS_V_4_9': 'OWASP_ASVS_V_4_9'),
  OWASP_ASVS_V_4_10: ('OWASP_ASVS_V_4_10': 'OWASP_ASVS_V_4_10'),
  OWASP_ASVS_V_5_1: ('OWASP_ASVS_V_5_1': 'OWASP_ASVS_V_5_1'),
  OWASP_ASVS_V_5_2: ('OWASP_ASVS_V_5_2': 'OWASP_ASVS_V_5_2'),
  OWASP_ASVS_V_5_3: ('OWASP_ASVS_V_5_3': 'OWASP_ASVS_V_5_3'),
  OWASP_ASVS_V_5_4: ('OWASP_ASVS_V_5_4': 'OWASP_ASVS_V_5_4'),
  OWASP_ASVS_V_5_5: ('OWASP_ASVS_V_5_5': 'OWASP_ASVS_V_5_5'),
  OWASP_ASVS_V_5_6: ('OWASP_ASVS_V_5_6': 'OWASP_ASVS_V_5_6'),
  OWASP_ASVS_V_5_7: ('OWASP_ASVS_V_5_7': 'OWASP_ASVS_V_5_7'),
  OWASP_ASVS_V_5_8: ('OWASP_ASVS_V_5_8': 'OWASP_ASVS_V_5_8'),
  OWASP_ASVS_V_5_9: ('OWASP_ASVS_V_5_9': 'OWASP_ASVS_V_5_9'),
  OWASP_ASVS_V_5_10: ('OWASP_ASVS_V_5_10': 'OWASP_ASVS_V_5_10'),
  OWASP_ASVS_V_6_1: ('OWASP_ASVS_V_6_1': 'OWASP_ASVS_V_6_1'),
  OWASP_ASVS_V_6_2: ('OWASP_ASVS_V_6_2': 'OWASP_ASVS_V_6_2'),
  OWASP_ASVS_V_6_3: ('OWASP_ASVS_V_6_3': 'OWASP_ASVS_V_6_3'),
  OWASP_ASVS_V_6_4: ('OWASP_ASVS_V_6_4': 'OWASP_ASVS_V_6_4'),
  OWASP_ASVS_V_6_5: ('OWASP_ASVS_V_6_5': 'OWASP_ASVS_V_6_5'),
  OWASP_ASVS_V_6_6: ('OWASP_ASVS_V_6_6': 'OWASP_ASVS_V_6_6'),
  OWASP_ASVS_V_6_7: ('OWASP_ASVS_V_6_7': 'OWASP_ASVS_V_6_7'),
  OWASP_ASVS_V_6_8: ('OWASP_ASVS_V_6_8': 'OWASP_ASVS_V_6_8'),
  OWASP_ASVS_V_6_9: ('OWASP_ASVS_V_6_9': 'OWASP_ASVS_V_6_9'),
  OWASP_ASVS_V_6_10: ('OWASP_ASVS_V_6_10': 'OWASP_ASVS_V_6_10'),
  OWASP_ASVS_V_7_1: ('OWASP_ASVS_V_7_1': 'OWASP_ASVS_V_7_1'),
  OWASP_ASVS_V_7_2: ('OWASP_ASVS_V_7_2': 'OWASP_ASVS_V_7_2'),
  OWASP_ASVS_V_7_3: ('OWASP_ASVS_V_7_3': 'OWASP_ASVS_V_7_3'),
  OWASP_ASVS_V_7_4: ('OWASP_ASVS_V_7_4': 'OWASP_ASVS_V_7_4'),
  OWASP_ASVS_V_7_5: ('OWASP_ASVS_V_7_5': 'OWASP_ASVS_V_7_5'),
  OWASP_ASVS_V_7_6: ('OWASP_ASVS_V_7_6': 'OWASP_ASVS_V_7_6'),
  OWASP_ASVS_V_7_7: ('OWASP_ASVS_V_7_7': 'OWASP_ASVS_V_7_7'),
  OWASP_ASVS_V_7_8: ('OWASP_ASVS_V_7_8': 'OWASP_ASVS_V_7_8'),
  OWASP_ASVS_V_7_9: ('OWASP_ASVS_V_7_9': 'OWASP_ASVS_V_7_9'),
  OWASP_ASVS_V_7_10: ('OWASP_ASVS_V_7_10': 'OWASP_ASVS_V_7_10'),
  OWASP_ASVS_V_8_1: ('OWASP_ASVS_V_8_1': 'OWASP_ASVS_V_8_1'),
  OWASP_ASVS_V_8_2: ('OWASP_ASVS_V_8_2': 'OWASP_ASVS_V_8_2'),
  OWASP_ASVS_V_8_3: ('OWASP_ASVS_V_8_3': 'OWASP_ASVS_V_8_3'),
  OWASP_ASVS_V_8_4: ('OWASP_ASVS_V_8_4': 'OWASP_ASVS_V_8_4'),
  OWASP_ASVS_V_8_5: ('OWASP_ASVS_V_8_5': 'OWASP_ASVS_V_8_5'),
  OWASP_ASVS_V_8_6: ('OWASP_ASVS_V_8_6': 'OWASP_ASVS_V_8_6'),
  OWASP_ASVS_V_8_7: ('OWASP_ASVS_V_8_7': 'OWASP_ASVS_V_8_7'),
  OWASP_ASVS_V_8_8: ('OWASP_ASVS_V_8_8': 'OWASP_ASVS_V_8_8'),
  OWASP_ASVS_V_8_9: ('OWASP_ASVS_V_8_9': 'OWASP_ASVS_V_8_9'),
  OWASP_ASVS_V_8_10: ('OWASP_ASVS_V_8_10': 'OWASP_ASVS_V_8_10'),
  OWASP_ASVS_V_9_1: ('OWASP_ASVS_V_9_1': 'OWASP_ASVS_V_9_1'),
  OWASP_ASVS_V_9_2: ('OWASP_ASVS_V_9_2': 'OWASP_ASVS_V_9_2'),
  OWASP_ASVS_V_9_3: ('OWASP_ASVS_V_9_3': 'OWASP_ASVS_V_9_3'),
  OWASP_ASVS_V_9_4: ('OWASP_ASVS_V_9_4': 'OWASP_ASVS_V_9_4'),
  OWASP_ASVS_V_9_5: ('OWASP_ASVS_V_9_5': 'OWASP_ASVS_V_9_5'),
  OWASP_ASVS_V_9_6: ('OWASP_ASVS_V_9_6': 'OWASP_ASVS_V_9_6'),
  OWASP_ASVS_V_9_7: ('OWASP_ASVS_V_9_7': 'OWASP_ASVS_V_9_7'),
  OWASP_ASVS_V_9_8: ('OWASP_ASVS_V_9_8': 'OWASP_ASVS_V_9_8'),
  OWASP_ASVS_V_9_9: ('OWASP_ASVS_V_9_9': 'OWASP_ASVS_V_9_9'),
  OWASP_ASVS_V_9_10: ('OWASP_ASVS_V_9_10': 'OWASP_ASVS_V_9_10'),
  OWASP_ASVS_V_10_1: ('OWASP_ASVS_V_10_1': 'OWASP_ASVS_V_10_1'),
  OWASP_ASVS_V_10_2: ('OWASP_ASVS_V_10_2': 'OWASP_ASVS_V_10_2'),
  OWASP_ASVS_V_10_3: ('OWASP_ASVS_V_10_3': 'OWASP_ASVS_V_10_3'),
  OWASP_ASVS_V_10_4: ('OWASP_ASVS_V_10_4': 'OWASP_ASVS_V_10_4'),
  OWASP_ASVS_V_10_5: ('OWASP_ASVS_V_10_5': 'OWASP_ASVS_V_10_5'),
  OWASP_ASVS_V_10_6: ('OWASP_ASVS_V_10_6': 'OWASP_ASVS_V_10_6'),
  OWASP_ASVS_V_10_7: ('OWASP_ASVS_V_10_7': 'OWASP_ASVS_V_10_7'),
  OWASP_ASVS_V_10_8: ('OWASP_ASVS_V_10_8': 'OWASP_ASVS_V_10_8'),
  OWASP_ASVS_V_10_9: ('OWASP_ASVS_V_10_9': 'OWASP_ASVS_V_10_9'),
  OWASP_ASVS_V_10_10: ('OWASP_ASVS_V_10_10': 'OWASP_ASVS_V_10_10'),
  OWASP_ASVS_V_11_1: ('OWASP_ASVS_V_11_1': 'OWASP_ASVS_V_11_1'),
  OWASP_ASVS_V_11_2: ('OWASP_ASVS_V_11_2': 'OWASP_ASVS_V_11_2'),
  OWASP_ASVS_V_11_3: ('OWASP_ASVS_V_11_3': 'OWASP_ASVS_V_11_3'),
  OWASP_ASVS_V_11_4: ('OWASP_ASVS_V_11_4': 'OWASP_ASVS_V_11_4'),
  OWASP_ASVS_V_11_5: ('OWASP_ASVS_V_11_5': 'OWASP_ASVS_V_11_5'),
  OWASP_ASVS_V_11_6: ('OWASP_ASVS_V_11_6': 'OWASP_ASVS_V_11_6'),
  OWASP_ASVS_V_11_7: ('OWASP_ASVS_V_11_7': 'OWASP_ASVS_V_11_7'),
  OWASP_ASVS_V_11_8: ('OWASP_ASVS_V_11_8': 'OWASP_ASVS_V_11_8'),
  OWASP_ASVS_V_11_9: ('OWASP_ASVS_V_11_9': 'OWASP_ASVS_V_11_9'),
  OWASP_ASVS_V_11_10: ('OWASP_ASVS_V_11_10': 'OWASP_ASVS_V_11_10'),
  OWASP_ASVS_V_12_1: ('OWASP_ASVS_V_12_1': 'OWASP_ASVS_V_12_1'),
  OWASP_ASVS_V_12_2: ('OWASP_ASVS_V_12_2': 'OWASP_ASVS_V_12_2'),
  OWASP_ASVS_V_12_3: ('OWASP_ASVS_V_12_3': 'OWASP_ASVS_V_12_3'),
  OWASP_ASVS_V_12_4: ('OWASP_ASVS_V_12_4': 'OWASP_ASVS_V_12_4'),
  OWASP_ASVS_V_12_5: ('OWASP_ASVS_V_12_5': 'OWASP_ASVS_V_12_5'),
  OWASP_ASVS_V_12_6: ('OWASP_ASVS_V_12_6': 'OWASP_ASVS_V_12_6'),
  OWASP_ASVS_V_12_7: ('OWASP_ASVS_V_12_7': 'OWASP_ASVS_V_12_7'),
  OWASP_ASVS_V_12_8: ('OWASP_ASVS_V_12_8': 'OWASP_ASVS_V_12_8'),
  OWASP_ASVS_V_12_9: ('OWASP_ASVS_V_12_9': 'OWASP_ASVS_V_12_9'),
  OWASP_ASVS_V_12_10: ('OWASP_ASVS_V_12_10': 'OWASP_ASVS_V_12_10'),
  OWASP_ASVS_V_13_1: ('OWASP_ASVS_V_13_1': 'OWASP_ASVS_V_13_1'),
  OWASP_ASVS_V_13_2: ('OWASP_ASVS_V_13_2': 'OWASP_ASVS_V_13_2'),
  OWASP_ASVS_V_13_3: ('OWASP_ASVS_V_13_3': 'OWASP_ASVS_V_13_3'),
  OWASP_ASVS_V_13_4: ('OWASP_ASVS_V_13_4': 'OWASP_ASVS_V_13_4'),
  OWASP_ASVS_V_13_5: ('OWASP_ASVS_V_13_5': 'OWASP_ASVS_V_13_5'),
  OWASP_ASVS_V_13_6: ('OWASP_ASVS_V_13_6': 'OWASP_ASVS_V_13_6'),
  OWASP_ASVS_V_13_7: ('OWASP_ASVS_V_13_7': 'OWASP_ASVS_V_13_7'),
  OWASP_ASVS_V_13_8: ('OWASP_ASVS_V_13_8': 'OWASP_ASVS_V_13_8'),
  OWASP_ASVS_V_13_9: ('OWASP_ASVS_V_13_9': 'OWASP_ASVS_V_13_9'),
  OWASP_ASVS_V_13_10: ('OWASP_ASVS_V_13_10': 'OWASP_ASVS_V_13_10'),
  OWASP_ASVS_V_14_1: ('OWASP_ASVS_V_14_1': 'OWASP_ASVS_V_14_1'),
  OWASP_ASVS_V_14_2: ('OWASP_ASVS_V_14_2': 'OWASP_ASVS_V_14_2'),
  OWASP_ASVS_V_14_3: ('OWASP_ASVS_V_14_3': 'OWASP_ASVS_V_14_3'),
  OWASP_ASVS_V_14_4: ('OWASP_ASVS_V_14_4': 'OWASP_ASVS_V_14_4'),
  OWASP_ASVS_V_14_5: ('OWASP_ASVS_V_14_5': 'OWASP_ASVS_V_14_5'),
  OWASP_ASVS_V_14_6: ('OWASP_ASVS_V_14_6': 'OWASP_ASVS_V_14_6'),
  OWASP_ASVS_V_14_7: ('OWASP_ASVS_V_14_7': 'OWASP_ASVS_V_14_7'),
  OWASP_ASVS_V_14_8: ('OWASP_ASVS_V_14_8': 'OWASP_ASVS_V_14_8'),
  OWASP_ASVS_V_14_9: ('OWASP_ASVS_V_14_9': 'OWASP_ASVS_V_14_9'),
  OWASP_ASVS_V_14_10: ('OWASP_ASVS_V_14_10': 'OWASP_ASVS_V_14_10'),
  // FFIEC Compliance – V.C.2(c)
  FFIEC_VC2_REQUIREMENT_1: ('FFIEC_VC2_REQUIREMENT_1': 'FFIEC_VC2_REQUIREMENT_1'),
  FFIEC_VC2_REQUIREMENT_2: ('FFIEC_VC2_REQUIREMENT_2': 'FFIEC_VC2_REQUIREMENT_2'),
  FFIEC_VC2_REQUIREMENT_3: ('FFIEC_VC2_REQUIREMENT_3': 'FFIEC_VC2_REQUIREMENT_3'),
  FFIEC_VC2_REQUIREMENT_4: ('FFIEC_VC2_REQUIREMENT_4': 'FFIEC_VC2_REQUIREMENT_4'),
  FFIEC_VC2_REQUIREMENT_5: ('FFIEC_VC2_REQUIREMENT_5': 'FFIEC_VC2_REQUIREMENT_5'),
  FFIEC_VC2_REQUIREMENT_6: ('FFIEC_VC2_REQUIREMENT_6': 'FFIEC_VC2_REQUIREMENT_6'),
  FFIEC_VC2_REQUIREMENT_7: ('FFIEC_VC2_REQUIREMENT_7': 'FFIEC_VC2_REQUIREMENT_7'),
  FFIEC_VC2_REQUIREMENT_8: ('FFIEC_VC2_REQUIREMENT_8': 'FFIEC_VC2_REQUIREMENT_8'),
  FFIEC_VC2_REQUIREMENT_9: ('FFIEC_VC2_REQUIREMENT_9': 'FFIEC_VC2_REQUIREMENT_9'),
  // CIS Benchmarks for Google Cloud Platform
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1: ('CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1': 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1'),
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3: ('CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3': 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3'),
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4: ('CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4': 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4'),
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5: ('CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5': 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5'),
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6: ('CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6': 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6'),

  // CIS Benchmarks for Kubernetes
  CIS_BENCHMARK_KUBERNETES_1: ('CIS_BENCHMARK_KUBERNETES_1': 'CIS_BENCHMARK_KUBERNETES_1'),
  CIS_BENCHMARK_KUBERNETES_3: ('CIS_BENCHMARK_KUBERNETES_3': 'CIS_BENCHMARK_KUBERNETES_3'),
  CIS_BENCHMARK_KUBERNETES_5: ('CIS_BENCHMARK_KUBERNETES_5': 'CIS_BENCHMARK_KUBERNETES_5'),

  // CIS Benchmarks for Amazon Web Services
  CIS_BENCHMARK_AWS_1: ('CIS_BENCHMARK_AWS_1': 'CIS_BENCHMARK_AWS_1'),
  CIS_BENCHMARK_AWS_2: ('CIS_BENCHMARK_AWS_2': 'CIS_BENCHMARK_AWS_2'),
  CIS_BENCHMARK_AWS_3: ('CIS_BENCHMARK_AWS_3': 'CIS_BENCHMARK_AWS_3'),

  // CIS Benchmarks Azure
  CIS_BENCHMARK_AZURE_3: ('CIS_BENCHMARK_AZURE_3': 'CIS_BENCHMARK_AZURE_3'),
  CIS_BENCHMARK_AZURE_4: ('CIS_BENCHMARK_AZURE_4': 'CIS_BENCHMARK_AZURE_4'),
  CIS_BENCHMARK_AZURE_6: ('CIS_BENCHMARK_AZURE_6': 'CIS_BENCHMARK_AZURE_6'),
  CIS_BENCHMARK_AZURE_7: ('CIS_BENCHMARK_AZURE_7': 'CIS_BENCHMARK_AZURE_7'),
  CIS_BENCHMARK_AZURE_8: ('CIS_BENCHMARK_AZURE_8': 'CIS_BENCHMARK_AZURE_8'),
  CIS_BENCHMARK_AZURE_9: ('CIS_BENCHMARK_AZURE_9': 'CIS_BENCHMARK_AZURE_9'),
};
export type PolicyComplianceStandardCriteriaType = $Keys<typeof PolicyComplianceStandardCriteria>;

export type PolicyComplianceReferenceType = {|
  +compliance_standard: PolicyComplianceStandardType,
  +compliance_standard_criteria: PolicyComplianceStandardCriteriaType,
  +description: string,
  +link: string,
|};
export type PolicyComplianceReferenceListType = $ReadOnlyArray<PolicyComplianceReferenceType>;

export const PolicyRuleGroupEnum = {
  REST_API_AND_SERVERLESS: 'REST_API_AND_SERVERLESS',
  CLOUD_SECURITY: 'CLOUD_SECURITY',
  WEB_APPS: 'WEB_APPS',
  ENCRYPTION: 'ENCRYPTION',
};

export type PolicyRuleGroupEnumType = $Keys<typeof PolicyRuleGroupEnum>;

export const WeekdaysEnum = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};

export type Weekdays = $Keys<typeof WeekdaysEnum>;

export type SqlInjectionConfig = {
  +http_methods_to_scan: $ReadOnlyArray<HTTPMethod>,
  +weekdays_when_to_scan: $ReadOnlyArray<Weekdays>,
  +time_when_to_scan: string,
};

export type ApiOperationPubliclyAccessibleConfig = {
  weekdays_when_to_scan: Array<Weekdays>,
  time_when_to_scan: string,
};

export type SslCertificateExpirationConfig = {
  nb_of_days_before_cert_expiration: number,
};

/** Special Scan Reports */

export type PiiReportsInApiResponse = {
  api_operation_id: string, // uuid4
  as_markdown: string,
  date_created: string,
  date_updated: string,
};

export type PiiReportsInApiResponsesList = $ReadOnlyArray<PiiReportsInApiResponse>;

export type PiiType = $Keys<typeof PiiTypeEnum>;

export type PiiTypeCustomizationConfig = {
  enabled_google_pii_types: Array<PiiType>,
};
export type PolicyRuleConfiguration =
  | SqlInjectionConfig
  | ApiOperationPubliclyAccessibleConfig
  | SslCertificateExpirationConfig
  | PiiTypeCustomizationConfig
  | PolicyRuleQuery_policy_by_id_policy_rules_configuration;

export const PiiTypeEnum = {
  ADVERTISING_ID: 'ADVERTISING_ID',
  AGE: 'AGE',
  CREDIT_CARD_NUMBER: 'CREDIT_CARD_NUMBER',
  CREDIT_CARD_TRACK_NUMBER: 'CREDIT_CARD_TRACK_NUMBER',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  FEMALE_NAME: 'FEMALE_NAME',
  FIRST_NAME: 'FIRST_NAME',
  GENDER: 'GENDER',
  IBAN_CODE: 'IBAN_CODE',
  HTTP_COOKIE: 'HTTP_COOKIE',
  ICD9_CODE: 'ICD9_CODE',
  ICD10_CODE: 'ICD10_CODE',
  IMEI_HARDWARE_ID: 'IMEI_HARDWARE_ID',
  LAST_NAME: 'LAST_NAME',
  LOCATION: 'LOCATION',
  MAC_ADDRESS: 'MAC_ADDRESS',
  MAC_ADDRESS_LOCAL: 'MAC_ADDRESS_LOCAL',
  MALE_NAME: 'MALE_NAME',
  MEDICAL_TERM: 'MEDICAL_TERM',
  PASSPORT: 'PASSPORT',
  PERSON_NAME: 'PERSON_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
  STREET_ADDRESS: 'STREET_ADDRESS',
  SWIFT_CODE: 'SWIFT_CODE',
  AUTH_TOKEN: 'AUTH_TOKEN',
  PASSWORD: 'PASSWORD',
  JAPAN_BANK_ACCOUNT: 'JAPAN_BANK_ACCOUNT',
  JAPAN_DRIVERS_LICENSE_NUMBER: 'JAPAN_DRIVERS_LICENSE_NUMBER',
  JAPAN_INDIVIDUAL_NUMBER: 'JAPAN_INDIVIDUAL_NUMBER',
  AMERICAN_BANKERS_CUSIP_ID: 'AMERICAN_BANKERS_CUSIP_ID',
  US_BANK_ROUTING_MICR: 'US_BANK_ROUTING_MICR',
  US_DRIVERS_LICENSE_NUMBER: 'US_DRIVERS_LICENSE_NUMBER',
  US_EMPLOYER_IDENTIFICATION_NUMBER: 'US_EMPLOYER_IDENTIFICATION_NUMBER',
  US_HEALTHCARE_NPI: 'US_HEALTHCARE_NPI',
  US_PASSPORT: 'US_PASSPORT',
  US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER: 'US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER',
  US_SOCIAL_SECURITY_NUMBER: 'US_SOCIAL_SECURITY_NUMBER',
  AWS_CREDENTIALS: 'AWS_CREDENTIALS',
  AZURE_AUTH_TOKEN: 'AZURE_AUTH_TOKEN',
  BASIC_AUTH_HEADER: 'BASIC_AUTH_HEADER',
  ENCRYPTION_KEY: 'ENCRYPTION_KEY',
  GCP_API_KEY: 'GCP_API_KEY',
  GCP_CREDENTIALS: 'GCP_CREDENTIALS',
  JSON_WEB_TOKEN: 'JSON_WEB_TOKEN',
  WEAK_PASSWORD_HASH: 'WEAK_PASSWORD_HASH',
  XSRF_TOKEN: 'XSRF_TOKEN',
};

export const PiiTypeEnumHumanReadable = {
  ADVERTISING_ID: 'Advertising ID',
  AGE: 'Age',
  CREDIT_CARD_NUMBER: 'Credit Card Number',
  CREDIT_CARD_TRACK_NUMBER: 'Credit Card Track Number',
  DATE_OF_BIRTH: 'Date of Birth',
  EMAIL_ADDRESS: 'Email Address',
  FEMALE_NAME: 'Female Name',
  FIRST_NAME: 'First Name',
  GENDER: 'Gender',
  IBAN_CODE: 'IBAN Code',
  HTTP_COOKIE: 'HTTP Cookie',
  ICD9_CODE: 'ICD-9-CM Code',
  ICD10_CODE: 'ICD-10-CM Code',
  IMEI_HARDWARE_ID: 'IMEI Hardware ID',
  LAST_NAME: 'Last Name',
  LOCATION: 'Location',
  MAC_ADDRESS: 'MAC Address',
  MAC_ADDRESS_LOCAL: 'MAC Address Local',
  MALE_NAME: 'Male Name',
  MEDICAL_TERM: 'Medical Term',
  PASSPORT: 'Passport',
  PERSON_NAME: 'Person Name',
  PHONE_NUMBER: 'Phone Number',
  STREET_ADDRESS: 'Street Address',
  SWIFT_CODE: 'SWIFT Code',
  AUTH_TOKEN: 'Auth Token',
  PASSWORD: 'Password',
  JAPAN_BANK_ACCOUNT: 'Japan Bank Account',
  JAPAN_DRIVERS_LICENSE_NUMBER: "Japan Driver's Licence Number",
  JAPAN_INDIVIDUAL_NUMBER: 'Japan Individual Number',
  AMERICAN_BANKERS_CUSIP_ID: 'American Bankers CUSIP ID',
  US_BANK_ROUTING_MICR: 'US Bank Routing MICR',
  US_DRIVERS_LICENSE_NUMBER: "US Driver's License Number",
  US_EMPLOYER_IDENTIFICATION_NUMBER: 'US Employer Identification Number',
  US_HEALTHCARE_NPI: 'US Healthcare NPI',
  US_PASSPORT: 'US Passport',
  US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER: 'US Preparer Taxpayer Identification Number',
  US_SOCIAL_SECURITY_NUMBER: 'US Social Security Number',
  AWS_CREDENTIALS: 'AWS Credentials',
  AZURE_AUTH_TOKEN: 'Azure Auth Token',
  BASIC_AUTH_HEADER: 'Basic Auth Header',
  ENCRYPTION_KEY: 'Encryption Key',
  GCP_API_KEY: 'GCP API Key',
  GCP_CREDENTIALS: 'GCP Credentials',
  JSON_WEB_TOKEN: 'JSON Web Token',
  WEAK_PASSWORD_HASH: 'Weak Password Hash',
  XSRF_TOKEN: 'XSRF Token',
};

export type PolicyRuleType = {
  id: string,
  title: string,
  description: string,
  recommendation: string,
  default_relevance: PolicyRuleTypeRelevanceType,

  configuration_default_value: null | PolicyRuleConfiguration,
  requires_configuration: boolean,
  compliance_policy_references: PolicyComplianceReferenceListType,
  group: PolicyRuleGroupEnumType,
};

export type PolicyRuleTypeList = $ReadOnlyArray<PolicyRuleType>;

export type PolicyRule = {
  id: string,
  belongs_to_policy_id: string,
  created_by_user_id: string,
  deleted_by_user_id: ?string,
  date_created: string,
  policy_rule_type_id: string,
  deletion_explanation: ?string,
  relevance: PolicyRuleTypeRelevanceType,
  configuration: null | PolicyRuleConfiguration,
  resolved_policy_violations_count: number,
  open_policy_violations_count: number,
};

export type PolicyRuleList = $ReadOnlyArray<PolicyRule>;

export const PolicyViolationException = {
  WONT_FIX: 'WONT_FIX', // The user decided to not resolve the policy violation
};

export const policyViolationStatus = {
  ...PolicyViolationException,
  OPEN: 'OPEN',
  WONT_FIX: 'WONT_FIX',
  RESOLVED: 'RESOLVED',
  DELETED: 'DELETED',
};

export type PolicyViolationStatus = $Keys<typeof policyViolationStatus>;

export type PolicyViolationExceptionType = $Keys<typeof PolicyViolationException>;

export type Remediation = {|
  remediation_instructions: string,
  remediation_url: string,
|};

export type PolicyViolation = {
  id: string,
  date_created: string,
  violated_policy_rule_id: string,
  date_resolved: null | string,
  date_deleted: null | string,
  additional_info: null | string,
  opened_by_certificate_chain_id: null | string,
  exception_type: ?PolicyViolationExceptionType,
  exception_explanation: null | string,
  exception_date_created: null | string,
  exception_added_by_user_id: null | string,
  affected_network_service_id: null | string,
  affected_api_operation_id: null | string,
  affected_cloud_resource_id: null | string,
  affected_web_application_id: null | string,
  affected_graphql_api_id: null | string,
  affected_restful_api_id: null | string,
  remediation: null | Remediation,
  status: PolicyViolationStatus,
};

export type PolicyViolationList = $ReadOnlyArray<PolicyViolation>;

/** RESTful APIs */

export type APIOperation = {|
  id: string,
  path: string,
  http_method: HTTPMethod,
  restful_api_id: string,
  is_authenticated: boolean,
  policy_violation_ids: $ReadOnlyArray<string>,
  date_created: string,
|};

export type APIOperationsList = $ReadOnlyArray<APIOperation>;

export const RestfulApiStatus = {
  ONLINE: 'ONLINE',
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE',
};

export type RestfulApiStatusEnum = $Keys<typeof RestfulApiStatus>;

export type RestfulAPI = {|
  id: string,
  network_service_id: string,
  openapi_definition_id: string,
  title: string,
  base_url: string,
  base_path: string,
  asset_type_name: string,
  asset_type_icon_url: string,
  api_operations: APIOperationsList,
  status: RestfulApiStatusEnum,

  discovered_via: DiscoveredViaType,
  discovered_via_apigee_authenticator_id: ?string,
  discovered_via_aws_authenticator_id: ?string,
  discovered_via_axway_authenticator_id: ?string,
  discovered_via_azure_authenticator_id: ?string,
  discovered_via_kong_authenticator_id: ?string,
  discovered_via_axway_authenticator_id: ?string,
  discovered_via_kong_authenticator_id: ?string,
  discovered_via_gcp_authenticator_id: ?string,
  discovered_via_mobile_application_id: ?string,
  discovered_via_mulesoft_authenticator_id: ?string,

  discovered_via_user_id: ?string,

  date_created: string,

  asset_group_memberships?: AssetGroupMembershipList,
|};

export type RestfulAPIsList = $ReadOnlyArray<RestfulAPI>;

export type OpenAPIDefinition = {
  id: string,
  title: string,
  discovered_via: DiscoveredViaType,
  discovered_via_aws_authenticator_id?: string,
  discovered_via_user_id?: string,
  original_document: string,
  date_created: string,
  ...
};

export type OpenAPIDefinitionsList = $ReadOnlyArray<OpenAPIDefinition>;

/** API Operations */

export type APIOperationScanTrace = {|
  id: string, // UUID
  url: string,
  api_operation_id: string, // UUID
  http_request: string,
  http_response: string,
|};

export type APIOperationScanTracesList = $ReadOnlyArray<APIOperationScanTrace>;

/** Domain Names */
export type DomainName = {
  id: string,
  name: string,
  discovered_via: DiscoveredViaType,
  discovered_via_apigee_authenticator_id: ?string,
  discovered_via_aws_authenticator_id: ?string,
  discovered_via_azure_authenticator_id: ?string,
  discovered_via_gcp_authenticator_id: ?string,
  discovered_via_mulesoft_authenticator_id: ?string,
  discovered_via_user_id?: string,
  date_created: string,
  ...
};

export type DomainNameList = $ReadOnlyArray<DomainName>;

export const AwsCloudResourcesEnum = {
  AWS_S3_BUCKET: 'AWS_S3_BUCKET',
  AWS_ELASTICSEARCH_DATABASE: 'AWS_ELASTICSEARCH_DATABASE',
  AWS_EBS_SNAPSHOT: 'AWS_EBS_SNAPSHOT',
  AWS_RDS_SNAPSHOT: 'AWS_RDS_SNAPSHOT',
  AWS_RDS_INSTANCE_SNAPSHOT: 'AWS_RDS_INSTANCE_SNAPSHOT',
  AWS_RDS_CLUSTER_SNAPSHOT: 'AWS_RDS_CLUSTER_SNAPSHOT',
  AWS_DYNAMODB_TABLE: 'AWS_DYNAMODB_TABLE',
  AWS_RDS_INSTANCE: 'AWS_RDS_INSTANCE',
  AWS_RDS_CLUSTER: 'AWS_RDS_CLUSTER',
  AWS_KMS_KEY: 'AWS_KMS_KEY',
  AWS_SQS_QUEUE: 'AWS_SQS_QUEUE',
  AWS_LAMBDA_FUNCTION: 'AWS_LAMBDA_FUNCTION',
  AWS_API_GATEWAY_API: 'AWS_API_GATEWAY_API',
  AWS_EBS_VOLUME: 'AWS_EBS_VOLUME',
  AWS_KINESIS_STREAM: 'AWS_KINESIS_STREAM',
  AWS_CLOUD_TRAIL: 'AWS_CLOUD_TRAIL',
  AWS_REDSHIFT_CLUSTER: 'AWS_REDSHIFT_CLUSTER',
  AWS_ELASTICACHE_REDIS_CLUSTER: 'AWS_ELASTICACHE_REDIS_CLUSTER',
  AWS_CLOUD_FRONT: 'AWS_CLOUD_FRONT',
  AWS_SAGE_MAKER: 'AWS_SAGE_MAKER',
  AWS_EFS_FILESYSTEM: 'AWS_EFS_FILESYSTEM',
  AWS_ELB_LOAD_BALANCER: 'AWS_ELB_LOAD_BALANCER',
  AWS_ELB_V2_LOAD_BALANCER: 'AWS_ELB_V2_LOAD_BALANCER',
  AWS_EC2_VIRTUAL_MACHINE: 'AWS_EC2_VIRTUAL_MACHINE',
  AWS_EC2_AUTOSCALING_GROUP: 'AWS_EC2_AUTOSCALING_GROUP',
  AWS_DOCDB_INSTANCE: 'AWS_DOCDB_INSTANCE',
};

export const AzureCloudResourcesEnum = {
  AZURE_STORAGE_ACCOUNT: 'AZURE_STORAGE_ACCOUNT',
  AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER: 'AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER',
  AZURE_WEB_APP: 'AZURE_WEB_APP',
  AZURE_FUNCTION_APP: 'AZURE_FUNCTION_APP',
  AZURE_FUNCTION: 'AZURE_FUNCTION',
  AZURE_API_MANAGEMENT_SERVICE: 'AZURE_API_MANAGEMENT_SERVICE',
  AZURE_API_MANAGEMENT_SERVICE_API: 'AZURE_API_MANAGEMENT_SERVICE_API',
  AZURE_SQLDB_SERVER: 'AZURE_SQLDB_SERVER',
  AZURE_SQLDB_INSTANCE: 'AZURE_SQLDB_INSTANCE',
  AZURE_KEY_VAULT: 'AZURE_KEY_VAULT',
  AZURE_KEY_VAULT_SECRET: 'AZURE_KEY_VAULT_SECRET',
  AZURE_APPLICATION_GATEWAY: 'AZURE_APPLICATION_GATEWAY',
  AZURE_MARIADB_INSTANCE: 'AZURE_MARIADB_INSTANCE',
  AZURE_MARIADB_SERVER: 'AZURE_MARIADB_SERVER',
  AZURE_COSMOSDB_SERVER: 'AZURE_COSMOSDB_SERVER',
  AZURE_COSMOSDB_INSTANCE: 'AZURE_COSMOSDB_INSTANCE',
  AZURE_POSTGRESQL_DATABASE: 'AZURE_POSTGRESQL_DATABASE',
  AZURE_POSTGRESQL_SERVER: 'AZURE_POSTGRESQL_SERVER',
};

export const GcpCloudResourcesEnum = {
  GCP_CLOUD_STORAGE_BUCKET: 'GCP_CLOUD_STORAGE_BUCKET',
  GCP_FIREBASE_FIRESTORE_DATABASE: 'GCP_FIREBASE_FIRESTORE_DATABASE',
  GCP_FIREBASE_REALTIME_DATABASE: 'GCP_FIREBASE_REALTIME_DATABASE',
  GCP_FIREBASE_STORAGE_BUCKET: 'GCP_FIREBASE_STORAGE_BUCKET',
  GCP_CLOUD_SQL_INSTANCE: 'GCP_CLOUD_SQL_INSTANCE',
  GCP_BIGTABLE_DATABASE: 'GCP_BIGTABLE_DATABASE',
  GCP_KUBERNETES_ENGINE_CLUSTER: 'GCP_KUBERNETES_ENGINE_CLUSTER',
  GCP_CLOUD_FUNCTION: 'GCP_CLOUD_FUNCTION',
  GCP_FIREBASE_FUNCTION: 'GCP_FIREBASE_FUNCTION',
  GCP_APP_ENGINE_SERVICE: 'GCP_APP_ENGINE_SERVICE',
  GCP_VIRTUAL_MACHINE: 'GCP_VIRTUAL_MACHINE',
};

export const MulesoftCloudResourcesEnum = {
  MULESOFT_HTTP_API: 'MULESOFT_HTTP_API',
  MULESOFT_REST_API: 'MULESOFT_REST_API',
};

export const UnknownCloudResourceEnum = { UNKNOWN: 'UNKNOWN' };

export const CloudResourceTypeDefinition = {
  ...UnknownCloudResourceEnum,
  ...AwsCloudResourcesEnum,
  ...AzureCloudResourcesEnum,
  ...GcpCloudResourcesEnum,
  ...MulesoftCloudResourcesEnum,
};
export type CloudResourceType = $Keys<typeof CloudResourceTypeDefinition>;

export type CloudResource = {|
  id: string,
  name: string,
  url: null | string,
  runtime: null | string,
  region: null | string,
  resource_type: string,
  asset_type_name: string,
  asset_type_icon_url: string,
  policy_violation_ids: $ReadOnlyArray<string>,
  date_created: string,
  cloud_console_url: string,

  // Discovered authenticator.
  discovered_via: DiscoveredViaType,
  discovered_via_apigee_authenticator_id: null | string,
  discovered_via_aws_authenticator_id: null | string,
  discovered_via_azure_authenticator_id: null | string,
  discovered_via_axway_authenticator_id: null | string,
  discovered_via_gcp_authenticator_id: null | string,
  discovered_via_mulesoft_authenticator_id: null | string,
  discovered_via_user_id: null | string,

  // Resource identifier.
  aws_arn: null | string,
  gcp_project_id: null | string,
  mulesoft_domain: null | string,
  azure_tenant_id: null | string,
  axway_org_id: null | string,
  kong_workspace_name: null | string,

  asset_group_memberships?: AssetGroupMembershipList,
|};

export type CloudResourcesList = $ReadOnlyArray<CloudResource>;

/** Events */

export const NetworkEventTypesDefinition = {
  NETWORK_SERVICE_DISCOVERED: 'NETWORK_SERVICE_DISCOVERED',
  NETWORK_SERVICE_WENT_OFFLINE: 'NETWORK_SERVICE_WENT_OFFLINE',
  NETWORK_SERVICE_WENT_ONLINE: 'NETWORK_SERVICE_WENT_ONLINE',
};

export const WebAppEventTypesDefinition = {
  WEB_APPLICATION_DISCOVERED: 'WEB_APPLICATION_DISCOVERED',
  // TODO(AD): Use for POR-1593
  WEB_APPLICATION_NO_LONGER_ACCESSIBLE: 'WEB_APPLICATION_NO_LONGER_ACCESSIBLE',
};

export const EventTypesDefinition = {
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  RESTFUL_API_DISCOVERED: 'RESTFUL_API_DISCOVERED',
  RESTFUL_API_UPDATED: 'RESTFUL_API_UPDATED',
  // TODO(AD): Use for POR-1593
  RESTFUL_API_NO_LONGER_ACCESSIBLE: 'RESTFUL_API_NO_LONGER_ACCESSIBLE',
  CLOUD_RESOURCE_DISCOVERED: 'CLOUD_RESOURCE_DISCOVERED',
  // TODO(AD): Use for POR-1593
  CLOUD_RESOURCE_NO_LONGER_ACCESSIBLE: 'CLOUD_RESOURCE_NO_LONGER_ACCESSIBLE',
  AWS_AUTHENTICATOR_CREATED: 'AWS_AUTHENTICATOR_CREATED',
  POLICY_VIOLATION_CREATED: 'POLICY_VIOLATION_CREATED',
  POLICY_VIOLATION_RESOLVED: 'POLICY_VIOLATION_RESOLVED',
  POLICY_VIOLATION_EXCEPTION_ADDED: 'POLICY_VIOLATION_EXCEPTION_ADDED',
  GCP_AUTHENTICATOR_CREATED: 'GCP_AUTHENTICATOR_CREATED',
  AZURE_AUTHENTICATOR_CREATED: 'AZURE_AUTHENTICATOR_CREATED',
  AXWAY_AUTHENTICATOR_CREATED: 'AXWAY_AUTHENTICATOR_CREATED',
  MULESOFT_AUTHENTICATOR_CREATED: 'MULESOFT_AUTHENTICATOR_CREATED',
  APIGEE_AUTHENTICATOR_CREATED: 'APIGEE_AUTHENTICATOR_CREATED',
  COMMENT_CREATED: 'COMMENT_CREATED',
  COMMENT_DELETED: 'COMMENT_DELETED',
  OPENAPI_DEFINITION_CREATED: 'OPENAPI_DEFINITION_CREATED',
  API_OPERATION_UPDATED: 'API_OPERATION_UPDATED',
  API_OPERATION_NO_LONGER_ACCESSIBLE: 'API_OPERATION_NO_LONGER_ACCESSIBLE',
  API_OPERATION_CREATED: 'API_OPERATION_CREATED',
  AZURE_AUTHENTICATOR_UPDATED: 'AZURE_AUTHENTICATOR_UPDATED',
  AXWAY_AUTHENTICATOR_UPDATED: 'AXWAY_AUTHENTICATOR_UPDATED',
  GCP_AUTHENTICATOR_KEY_UPDATED: 'GCP_AUTHENTICATOR_KEY_UPDATED',
  CERTIFICATE_CHAIN_DISCOVERED: 'CERTIFICATE_CHAIN_DISCOVERED',
  CERTIFICATE_CHAIN_REMOVED: 'CERTIFICATE_CHAIN_REMOVED',
  CERTIFICATE_DISCOVERED: 'CERTIFICATE_DISCOVERED',
  POLICY_RULE_CREATED: 'POLICY_RULE_CREATED',
  POLICY_RULE_DELETED: 'POLICY_RULE_DELETED',
  ...NetworkEventTypesDefinition,
  ...WebAppEventTypesDefinition,
};
export type EventType = $Keys<typeof EventTypesDefinition>;

export const EventImportancesDefinition = {
  INFORMATIONAL: 'INFORMATIONAL',
  WARNING: 'WARNING',
  CRITICAL: 'CRITICAL',
  RESOLUTION: 'RESOLUTION',
};

export type EventImportance = $Keys<typeof EventImportancesDefinition>;

export type Event = {
  id: string,
  date_created: string,
  event_type: EventType,
  event_importance: EventImportance,
  user_id?: string,
  network_service_id?: string,
  restful_api_id?: string,
  cloud_resource_id?: string,
  aws_authenticator_id?: string,
  axway_authenticator_id?: string,
  policy_violation_id?: string,
  gcp_authenticator_id?: string,
  azure_authenticator_id?: string,
  mulesoft_authenticator_id?: string,
  apigee_authenticator_id?: string,
  comment_id?: string,
  api_operation_id?: string,
  web_application_id?: string,
  ...
};

export type EventsList = $ReadOnlyArray<Event>;

/** Users */

export type User = {
  id: string,
  first_name: string,
  last_name: string,
  login_email: string,
  date_created: string,
  notification_email: ?string,
  ...
};

export type UsersList = $ReadOnlyArray<User>;

/** Comments */

export type Comment = {|
  +id: string,
  +content: ?string, // Content is null when comment is deleted
  +added_by_user_id: string,
  +date_created: string,
  +related_policy_violation_id: string,
|};

export type CommentsList = $ReadOnlyArray<Comment>;

/** Certificate Chains */

export const SecurityPropertiesEnum = {
  MATCHES_HOSTNAME: 'MATCHES_HOSTNAME',
  IS_EXPIRING: 'IS_EXPIRING',
  SUPPORTS_MUST_STAPLE: 'SUPPORTS_MUST_STAPLE',
  EMBEDS_CERTIFICATE_TRANSPARENCY_SCT: 'EMBEDS_CERTIFICATE_TRANSPARENCY_SCT',
  VULNERABLE_TO_SHA1_COLLISION: 'VULNERABLE_TO_SHA1_COLLISION',
  RSA_KEY_SMALLER_THAN_2048_BITS: 'RSA_KEY_SMALLER_THAN_2048_BITS',
  LEGACY_SYMANTEC_ANCHOR: 'LEGACY_SYMANTEC_ANCHOR',
};

export type SecurityPropertiesTypeEnum = $Keys<typeof SecurityPropertiesEnum>;

export type SecurityProperties = {|
  +enum: SecurityPropertiesTypeEnum,
  +value: boolean,
  +relevance: null | PolicyRuleTypeRelevanceType,
|};

export type CertificateChain = {|
  +id: string,
  +deployed_on_network_service_id: string,
  +date_created: string,
  +date_removed: null | string,
  +security_properties: $ReadOnlyArray<SecurityProperties>,
  +certificate_ids: $ReadOnlyArray<string>,
|};

export type CertificateChainList = $ReadOnlyArray<CertificateChain>;

/** Certificates */

export type Certificate = {|
  +id: string,
  +date_created: string,
  +subject_as_rfc4514_string: string,
  +issuer_as_rfc4514_string: string,
  +serial_number: string,
  +public_key_pin: string,
  +public_key_size: number,
  +public_key_algorithm: string,
  +signature_hash_algorithm: string,
  +not_valid_before: string,
  +not_valid_after: string,
  +is_end_entity_certificate: boolean,
  +sha256_fingerprint: string,
  +sha1_fingerprint: string,
  +domain_names_in_cn: $ReadOnlyArray<string>,
  +domain_names_in_san: $ReadOnlyArray<string>,
|};

export type CertificateList = $ReadOnlyArray<Certificate>;

export type Authenticator = {|
  id: string,
  date_created: Date,
  added_by_user_id: string,
|};

export type PaginatedResponse<Response> = {|
  ...Response,
  pagination_information: {|
    next_cursor?: string,
    total_count?: number,
  |},
|};

/** Web applications */

export type WebLibrary = {|
  name: string,
  project_url: string,
  icon_url: string,
|};

export type WebLibraryList = $ReadOnlyArray<WebLibrary>;

export const WebRequestTypeEnum = {
  XHR: 'XHR',
  FETCH: 'FETCH',
  FONT: 'FONT',
  SCRIPT: 'SCRIPT',
  STYLESHEET: 'STYLESHEET',
  IMAGE: 'IMAGE',
  MEDIA: 'MEDIA',
  DOCUMENT: 'DOCUMENT',
  TEXTTRACK: 'TEXTTRACK',
  EVENTSOURCE: 'EVENTSOURCE',
  WEBSOCKET: 'WEBSOCKET',
  MANIFEST: 'MANIFEST',
  OTHER: 'OTHER',
};

export type WebRequestType = $Keys<typeof WebRequestTypeEnum>;

export type WebActivity = {|
  base_url: string,
  request_types: $ReadOnlyArray<WebRequestType>,
|};

export type WebActivityList = $ReadOnlyArray<WebActivity>;

export const WebApplicationTypeEnum = {
  SINGLE_PAGE: 'SINGLE_PAGE',
  TRADITIONAL: 'TRADITIONAL',
  HYBRID: 'HYBRID',
};

export type WebApplicationType = $Keys<typeof WebApplicationTypeEnum>;

export type WebAnalysisResult = {|
  title: string,
  screenshot_url: string,
  fullscreen_screenshot_url: string,
  web_application_type: WebApplicationType,
  date_created: string,
  date_last_received: string,
  detected_libraries: ?WebLibraryList,
  detected_network_activity: null | WebActivityList,
  detected_restful_api_ids: null | $ReadOnlyArray<string>,
  exercised_api_operation_ids: null | $ReadOnlyArray<string>,
|};

export type WebAnalysisResultList = $ReadOnlyArray<WebAnalysisResult>;

export const WebApplicationStatusEnum = {
  ONLINE: 'ONLINE',
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE',
};

export type WebApplicationStatusType = $Keys<typeof WebApplicationStatusEnum>;

export type WebApplication = {|
  id: string,
  base_url: string,
  base_path: string,
  status: WebApplicationStatusType,
  date_created: string,
  asset_type_name: string,
  asset_type_icon_url: string,
  network_service_id: string,
  most_recent_analysis_result: WebAnalysisResult,
  discovered_via: DiscoveredViaType,
  discovered_via_aws_authenticator_id: null | string,
  discovered_via_axway_authenticator_id: null | string,
  discovered_via_gcp_authenticator_id: null | string,
  discovered_via_azure_authenticator_id: null | string,
  discovered_via_axway_authenticator_id: null | string,
  discovered_via_mulesoft_authenticator_id: null | string,
  discovered_via_apigee_authenticator_id: null | string,
  discovered_via_kong_authenticator_id: null | string,
  discovered_via_mobile_application_id: null | string,
  discovered_via_apigee_authenticator_id: null | string,
  discovered_via_user_id: null | string,

  policy_violation_ids: $ReadOnlyArray<string>,

  asset_group_memberships?: AssetGroupMembershipList,
|};

export type WebApplicationList = $ReadOnlyArray<WebApplication>;

export type GraphqlApi = {|
  id: string,
  asset_type_name: string,
  asset_type_icon_url: string,
  aws_arn: null | string,
  azure_tenant_id: null | string,
  gcp_project_id: null | string,
  date_created: string,
  mulesoft_asset_id: null | string,
  network_service_id: string,
  discovered_via: DiscoveredViaType,
  discovered_via_aws_authenticator_id: null | string,
  discovered_via_axway_authenticator_id: null | string,
  discovered_via_gcp_authenticator_id: null | string,
  discovered_via_azure_authenticator_id: null | string,
  discovered_via_axway_authenticator_id: null | string,
  discovered_via_mulesoft_authenticator_id: null | string,
  discovered_via_apigee_authenticator_id: null | string,
  discovered_via_kong_authenticator_id: null | string,
  discovered_via_mobile_application_id: null | string,
  discovered_via_apigee_authenticator_id: null | string,
  discovered_via_user_id: null | string,
  status: GraphQlApiStatusEnum,
  url: string,

  policy_violation_ids: $ReadOnlyArray<string>,

  asset_group_memberships?: AssetGroupMembershipList,
|};
export type GraphqlApiList = $ReadOnlyArray<GraphqlApi>;

export const GraphQlApiStatus = {
  ONLINE: 'ONLINE',
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE',
};
export type GraphQlApiStatusEnum = $Keys<typeof GraphQlApiStatus>;

export type IPRange = {|
  id: string,
  ip_range: string,
  has_continuous_discovery_enabled: boolean,
|};

export type IPRangeList = $ReadOnlyArray<IPRange>;

export type IPRangeScanResult = {|
  +ip_address: string,
  +online_network_service_urls: $ReadOnlyArray<string>,
|};
export type IPRangeScanResultList = $ReadOnlyArray<IPRangeScanResult>;

export type IPRangeScan = {|
  +ip_range: string,
  +ip_range_id?: string,
  +ip_address_scan_results: IPRangeScanResultList,
|};

export type IPRangeScanList = $ReadOnlyArray<IPRangeScan>;
