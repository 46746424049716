var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { accessControlsApi, accessControlsLandingPage, accessControlsShare, userManagementControlsApi,
// @ts-ignore Ignore JS files
 } from './redux/access_controls';
import Components from './routeComponents';
// prettier-ignore
// @ts-nocheck
var settingsV2Routes = {
    '/management/v2/activity/:search': Components.SettingsActivity,
    '/management/v2/activity': Components.SettingsActivity,
    '/management/v2/compliance-report': Components.SettingsCompliance,
    '/management/v2/profile': Components.SettingsProfile,
    '/management/v2/users': Components.SettingsManageUsers,
    '/management/v2/users/:id': Components.SettingsManageUsersEdit,
    '/management/v2/create/users': Components.SettingsManageUsersEdit,
    '/management/v2/preferences': Components.ManagementAlerts,
    '/management/v2/shared-links': Components.AcheronSharedLinks,
    '/management/share/error': Components.ErrorPage
};
// prettier-ignore
// @ts-nocheck
var devsecopsV2Routes = {
    '/devsecops/v2': Components.DevSecopsV2Page,
    '/devsecops/v2/stanford-dish-security-university': Components.StanfordDishUniHomeV2,
    '/devsecops/v2/stanford-dish-security-university/materials': Components.StanfordDishUniViewAllMaterialsV2,
    '/devsecops/v2/mobile-app-upload': Components.MobileAppUploadPage,
    '/devsecops/v2/aws-security-hub': Components.AwsSecurityHubPage,
    '/devsecops/v2/saml': Components.DevSecOpsSaml,
    '/devsecops/v2/integrations/jira/options': Components.DevSecOpsJiraOptions,
    '/devsecops/v2/integrations/jira': Components.DevSecOpsJiraLandingPage,
    '/devsecops/v2/integrations/jira/configure': Components.DevSecOpsJiraConfigurePage,
    '/devsecops/v2/integrations/jira/configure/:integrationId': Components.DevSecOpsJiraConfigurePage,
    '/devsecops/v2/integrations/jira/apps': Components.DevSecOpsJiraMobileAppsPage,
    '/devsecops/v2/integrations/jira/global': Components.DevSecOpsJiraMobileFormPage,
    '/devsecops/v2/integrations/jira/:id': Components.DevSecOpsJiraMobileFormPage,
    '/devsecops/v2/integrations/servicenow': Components.DevSecOpsServiceNowLandingPage,
    '/devsecops/v2/integrations/servicenow/configure': Components.DevSecOpsServiceNowConfigurePage,
    '/devsecops/v2/integrations/servicenow/configure/:id': Components.DevSecOpsServiceNowConfigurePage,
    '/devsecops/v2/integrations/datadog': Components.DevSecOpsDatadogLandingPage,
    '/devsecops/v2/integrations/datadog/configure': Components.DevSecOpsDatadogConfigurePage,
    '/devsecops/v2/integrations/datadog/configure/:id': Components.DevSecOpsDatadogConfigurePage,
    '/devsecops/v2/results_api_access': Components.ResultsApiAccessPage,
    '/devsecops/v2/api_keys/create': Components.DevSecOpsApiKeysCreatePage,
    '/devsecops/v2/api_keys/:id': Components.DevSecOpsApiKeysEditPage,
    '/devsecops/v2/integrations/webhooks': Components.DevSecOpsWebhookIntegrationPage,
    '/devsecops/v2/integrations/slack-cliq-msft': Components.DevSecOpsSlackMicrosoftTeamsIntegrationPage,
    '/devsecops/v2/scancicd': Components.DevSecOpsScanCICD,
    '/devsecops/v2/sast-code-analysis': Components.DevSecOpsSASTCodeAnalysis,
    '/devsecops/v2/manual-source-code-upload': '/devsecops/v2/manual-source-code-upload/upload',
    '/devsecops/v2/manual-source-code-upload/:currentTab': Components.DevSecOpsUploadSourceCode,
    '/devsecops/v2/sast-configuration': Components.DevSecOpsSastConfigurationPage,
    '/devsecops/v2/sast-code-analysis/github-enterprise-server': Components.DevSecOpsGithubOnPremConfiguration,
};
// prettier-ignore
// @ts-nocheck
var apiV2Routes = {
    '/api/v2': '/api/v2/dashboard',
    '/api/v2/dashboard': Components.ApiProtectV2Dashboard,
    '/api/v2/active-protection': '/api/v2/active-protection/overview',
    '/api/v2/active-protection/:currentTab': Components.ApiProtectV2Page,
    '/api/v2/active-protection/:currentTab/details/:restfulApiId/:requestId': Components.ApiProtectV2Page,
    '/api/v2/security': '/api/v2/security/hack-and-extract',
    '/api/v2/security/:currentTab': Components.ApiProtectSecurityV2Page,
    '/api/v2/security/repositories/:repositoryId': Components.ApiProtectSecurityV2Page,
    '/api/v2/inventory': '/api/v2/inventory/apis',
    '/api/v2/inventory/:currentTab': Components.ApiInventoryV2Page,
    '/api/v2/policies': Components.ApiPoliciesV2Page,
    '/api/v2/policies/:id': '/api/v2/policies/:id/api',
    '/api/v2/policies/:id/:currentTab': Components.ApiPoliciesV2UuidPage,
    '/api/v2/asset-groups/:id': Components.ApiProtectV2AssetGroupsUuidPage,
    '/api/v2/assets/:id': '/api/v2/assets/:id/overview',
    '/api/v2/assets/:id/:currentTab': Components.ApiV2AssetsUuidPage,
    '/api/v2/graphql-apis/:id': Components.ApiV2GraphqlApisUuidPage,
    '/api/v2/graphql-apis/:id/:currentTab': Components.ApiV2GraphqlApisUuidPage,
    '/api/v2/inspect/policy-violations': '/api/v2/policy-violations',
    '/api/v2/inspect/policy-violations/:id': '/api/v2/policy-violations/:id',
    '/api/v2/policy-violations': Components.ApiV2PolicyViolationsPage,
    '/api/v2/policy-violations/:id': Components.ApiV2PolicyViolationsUuidPage,
    '/api/v2/protect/uuid/:id': '/api/v2/protect/uuid/:id/overview',
    '/api/v2/protect/uuid/:id/:currentTab': Components.ApiV2ProtectUuidPage,
    '/api/v2/api-operations/:id': Components.ApiV2OperationDetailsContainer,
    '/api/v2/network-services/:id': Components.ApiV2NetworkServicesUuidPage,
    '/api/v2/network-services/:id/:currentTab': Components.ApiV2NetworkServicesUuidPage,
    '/api/v2/web-applications/:id': Components.ApiV2WebApplicationsUuidPage,
    '/api/v2/web-applications/:id/:currentTab': Components.ApiV2WebApplicationsUuidPage,
    '/api/v2/cloud-resources/:id': Components.ApiCloudResourcesUuidPage,
    '/api/v2/cloud-resources/:id/:currentTab': Components.ApiCloudResourcesUuidPage,
};
// prettier-ignore
// @ts-nocheck
var mobileV2Routes = {
    '/mobile-secure/v2': '/mobile-secure/v2/dashboard',
    '/mobile-secure/v2/security': '/mobile-secure/v2/security/issues',
    '/mobile-secure/v2/security/:currentTab/:category/:uuid': Components.MobileSecureV2SecurityPage,
    '/mobile-secure/v2/security/:currentTab/:uuid': Components.MobileSecureV2SecurityPage,
    '/mobile-secure/v2/security/:currentTab': Components.MobileSecureV2SecurityPage,
    '/mobile-secure/v2/dashboard': Components.MobileSecureV2Dashboard,
    '/mobile-secure/v2/inventory': '/mobile-secure/v2/inventory/apis',
    '/mobile-secure/v2/inventory/:currentTab': Components.MobileSecureV2InventoryPage,
    '/mobile-secure/v2/active-protection': '/mobile-secure/v2/active-protection/overview',
    '/mobile-secure/v2/active-protection/:currentTab': Components.MobileProtectV2ActiveProtectionPage,
    '/mobile-secure/v2/active-protection/:currentTab/details/:mobileAppId/:eventId': Components.MobileProtectV2ActiveProtectionPage,
    '/mobile-secure/v2/reports': Components.MobileProtectV2Reports
};
// prettier-ignore
// @ts-nocheck
var webV2Routes = {
    '/web/v2': '/web/v2/dashboard',
    '/web/v2/dashboard': Components.WebSecureV2DashboardPage,
    '/web/v2/security': '/web/v2/security/issues',
    '/web/v2/security/:currentTab': Components.WebSecureV2SecurityPage,
    '/web/v2/security/:currentTab/*': Components.WebSecureV2SecurityPage,
    '/web/v2/inventory': '/web/v2/inventory/apis',
    '/web/v2/inventory/:currentTab': Components.WebSecureV2InventoryPage,
    '/web/v2/active-protection': '/web/v2/active-protection/overview',
    '/web/v2/active-protection/:currentTab': Components.WebProtectV2ActiveProtectionPage,
};
// prettier-ignore
// @ts-nocheck
var cloudV2Routes = {
    '/cloud/v2': '/cloud/v2/dashboard',
    '/cloud/v2/dashboard': Components.cloudSecureV2DashboardPage,
    '/cloud/v2/inventory': '/cloud/v2/inventory/apis',
    '/cloud/v2/inventory/:currentTab': Components.cloudSecureV2InventoryPage,
    '/cloud/v2/security': '/cloud/v2/security/issues',
    '/cloud/v2/security/:currentTab': Components.cloudSecureV2SecurityPage,
    '/cloud/v2/active-protection': '/cloud/v2/active-protection/cloud-abuse',
    '/cloud/v2/active-protection/:currentTab': Components.cloudSecureV2ActiveProtectionPage,
    '/cloud/v2/asset-groups/:id': Components.cloudSecureV2AssetGroupsPage,
    '/cloud/v2/cloud-resources/:id': Components.ApiCloudResourcesUuidPage,
    '/cloud/v2/cloud-resources/:id/:currentTab': Components.ApiCloudResourcesUuidPage,
};
// prettier-ignore
// @ts-nocheck
var openScanV2Routes = {
    '/openscan/v2': '/openscan/v2/dashboard',
    '/openscan/v2/dashboard': Components.OpenScanV2DashboardPage,
    '/openscan/v2/search': Components.OpenScanV2SearchPage,
    '/openscan/v2/apps/:appId': '/openscan/v2/apps/:appId/overview',
    '/openscan/v2/apps/:appId/:currentTab': Components.OpenScanV2AppPage,
};
// prettier-ignore
// @ts-nocheck
var apiRoutes = {
    '/api': '/api/overview',
    '/api/assets/:id': '/api/assets/:id/overview',
    '/api/assets/:id/:currentTab': Components.ApiAssetsUuidPage,
    '/api/asm-setup': Components.ApiAsmSetupPage,
    '/api/asset-groups/:id': Components.ApiAssetGroupsUuidPage,
    '/api/custom-checks': Components.ApiCustomChecksPage,
    '/api/discover/shadow-assets': '/api/shadow-assets',
    '/api/discover/configuration': '/api/asm-setup',
    '/api/discover/inventory': '/api/inventory',
    '/api/discover/inventory/:currentTab': '/api/inventory/:currentTab',
    '/api/inspect/policy-violations': '/api/policy-violations',
    '/api/inspect/policy-violations/:id': '/api/policy-violations/:id',
    '/api/inventory': '/api/inventory/api-assets',
    '/api/inventory/inventory': '/api/inventory/api-assets',
    '/api/inventory/:currentTab': Components.ApiInventoryPage,
    '/api/policies': Components.ApiPoliciesPage,
    '/api/policies/:id': '/api/policies/:id/api',
    '/api/policies/:id/:currentTab': Components.ApiPoliciesUuidPage,
    '/api/policy': '/api/policies',
    '/api/policy/:id': '/api/policies/:id',
    '/api/policy-violations': Components.ApiPolicyViolationsPage,
    '/api/policy-violations/:id': Components.ApiPolicyViolationsUuidPage,
    '/api/protect': '/api/protect/overview',
    '/api/protect/uuid/:id': '/api/protect/uuid/:id/overview',
    '/api/protect/uuid/:id/:currentTab': Components.ApiProtectUuidPage,
    '/api/protect/:currentTab': Components.ApiProtectionPage,
    '/api/mobile_applications/:id': Components.ApiMobileApplicationLoadingPage,
    '/api/shadow-assets': Components.ApiShadowAssetsPage,
    '/api/graphql-apis/:id': Components.ApiGraphqlApisUuidPage,
    '/api/graphql-apis/:id/:currentTab': Components.ApiGraphqlApisUuidPage,
    '/api/restful-apis/:id': '/api/assets/:id/overview',
    '/api/restful-apis/:id/:currentTab': '/api/assets/:id/:currentTab',
    '/api/api-operations/:id': accessControlsApi(Components.ApolloApiOperationDetailsContainer),
    '/api/cloud-resources/:id': Components.ApiCloudResourcesUuidPage,
    '/api/cloud-resources/:id/:currentTab': Components.ApiCloudResourcesUuidPage,
    '/api/inspect/detect-and-inject': accessControlsApi(Components.ApolloDetectInjectContainer),
    '/api/inspect/hack-and-extract': accessControlsApi(Components.ApolloHackExtractContainer),
    '/api/inspect/leaky-apis': accessControlsApi(Components.ApolloLeakyApisContainer),
    '/api/inspect/policy-rules/:id': accessControlsApi(Components.ApolloPolicyRuleWithViolationsAndDetails),
    '/api/inspect/policy-rules/:id/:policy_violation_id': accessControlsApi(Components.ApolloPolicyRuleWithViolationsAndDetails),
    '/api/network-services/:id': Components.APINetworkServicesUuidPage,
    '/api/network-services/:id/:currentTab': Components.APINetworkServicesUuidPage,
    '/api/overview': accessControlsApi(Components.ApolloOverview),
    '/api/overview/detail': accessControlsApi(Components.ApolloOverviewDetail),
    '/api/share/:token/cloud-resources/:id': '/management/share/:token/cloud-resources/:id',
    '/api/share/:token/cloud-resources/:id/:currentTab': '/management/share/:token/cloud-resources/:id/:currentTab',
    '/api/share/:token/network-services/:id': '/management/share/:token/network-services/:id',
    '/api/share/:token/network-services/:id/:currentTab': '/management/share/:token/network-services/:id/:currentTab',
    '/api/share/:token/onboard': '/management/share/:token/onboard',
    '/api/share/:token/onboarding': '/management/share/:token/onboarding',
    '/api/share/:token/restful-apis/:id': '/management/share/:token/restful-apis/:id',
    '/api/share/:token/restful-apis/:id/:currentTab': '/management/share/:token/restful-apis/:id/:currentTab',
    '/api/share/:token/violations/:id': '/management/share/:token/violations/:id',
    '/api/share/:token/web-applications/:id': '/management/share/:token/web-applications/:id',
    '/api/share/:token/web-applications/:id/:currentTab': '/management/share/:token/web-applications/:id/:currentTab',
    '/api/shared-links': accessControlsApi(Components.ApolloSharedLinks),
    '/api/ssrf-sploits-toolkit': accessControlsApi(Components.ApolloSsrfSploitsContainer),
    '/api/web-applications/:id': Components.ApiWebApplicationsUuidPage,
    '/api/web-applications/:id/:currentTab': Components.ApiWebApplicationsUuidPage,
};
// prettier-ignore
var cloudRoutes = {
    '/cloud': Components.CloudPage,
    '/cloud/assets/:id': '/cloud/assets/:id/overview',
    '/cloud/assets/:id/:currentTab': Components.CloudAssetsUuidPage,
    '/cloud/activity': Components.CloudActivityPage,
    '/cloud/asm-setup': Components.CloudAsmSetupPage,
    '/cloud/asset-groups/:id': Components.CloudAssetGroupsUuidPage,
    '/cloud/cloud-resources/:id': Components.CloudCloudResourcesUuidPage,
    '/cloud/cloud-resources/:id/:currentTab': Components.CloudCloudResourcesUuidPage,
    '/cloud/inventory': '/cloud/inventory/cloud-assets',
    '/cloud/inventory/inventory': '/cloud/inventory/cloud-assets',
    '/cloud/inventory/:currentTab': Components.CloudInventoryPage,
    '/cloud/network-services/:id': Components.CloudNetworkServicesUuidPage,
    '/cloud/network-services/:id/:currentTab': Components.CloudNetworkServicesUuidPage,
    '/cloud/policies': Components.CloudPoliciesPage,
    '/cloud/iac-scan': Components.CloudIacScanPage,
    '/cloud/policies/:id': '/cloud/policies/:id/cloud',
    '/cloud/policies/:id/:currentTab': Components.CloudPoliciesUuidPage,
    '/cloud/policies/:id/custom-checks/:customCheckId': Components.CloudPoliciesUuidPage,
    '/cloud/policy-violations': Components.CloudPolicyViolationsPage,
    '/cloud/policy-violations/:id': Components.CloudPolicyViolationsUuidPage,
    '/cloud/security-toolkits': Components.CloudToolkitsPage,
    '/cloud/security-toolkits/cloud-gotchas': Components.CloudToolkitsGotchasPage,
    '/cloud/security-toolkits/cloud-gotchas/:currentTab': Components.CloudToolkitsGotchasPage,
    '/cloud/security-toolkits/cloud-leaks': Components.CloudToolkitsLeaksPage,
    '/cloud/security-toolkits/code-red': Components.CloudToolkitsCodeRedPage,
    '/cloud/security-toolkits/dlp': '/cloud/security-toolkits/dlp/overview',
    '/cloud/security-toolkits/dlp/:currentTab': Components.CloudToolkitsDlpPage,
    '/cloud/security-toolkits/correct-and-protect': Components.ApolloCorrectAndProjectContainer,
    '/cloud/security-toolkits/major-malfunction': Components.MajorMalfunctionPage,
    '/cloud/security-toolkits/ransomware': '/cloud/security-toolkits/ransomware/overview',
    '/cloud/security-toolkits/ransomware/:currentTab': Components.CloudToolkitsRansomwarePage,
    '/cloud/shadow-assets': Components.CloudShadowAssetsPage,
    '/cloud/web-applications/:id': Components.CloudWebApplicationsUuid,
    '/cloud/web-applications/:id/:currentTab': Components.CloudWebApplicationsUuid,
    '/cloud/graphql-apis/:id': Components.CloudGraphqlApisUuidPage,
    '/cloud/graphql-apis/:id/:currentTab': Components.CloudGraphqlApisUuidPage,
    '/cloud/security-toolkits/xdr': Components.CloudXDRPage,
    '/cloud/active-protection/ciem': Components.CloudCIEMPage,
    '/cloud/active-protection/ciem/demo': Components.CloudCIEMPageDemo,
    '/cloud/security-toolkits/correct-and-protect/:id': accessControlsApi(Components.PolicyRuleWithViolationsAndDetailsCorrectAndProtect),
};
// prettier-ignore
var webRoutes = {
    '/web/': Components.WebPage,
    '/web/asm-setup': Components.WebAsmSetupPage,
    '/web/assets/:id': '/web/assets/:id/overview',
    '/web/assets/:id/:currentTab': Components.WebAssetsUuidPage,
    '/web/asset-groups/:id': Components.WebAssetGroupsUuidPage,
    '/web/cloud-resources/:id': Components.WebCloudResourcesUuidPage,
    '/web/cloud-resources/:id/:currentTab': Components.WebCloudResourcesUuidPage,
    '/web/custom-checks': Components.WebCustomChecksPage,
    '/web/graphql-apis/:id': Components.WebGraphqlApisUuidPage,
    '/web/graphql-apis/:id/:currentTab': Components.WebGraphqlApisUuidPage,
    '/web/inventory': '/web/inventory/web-assets',
    '/web/inventory/inventory': '/web/inventory/web-assets',
    '/web/inventory/web-apps': '/web/inventory/web-assets',
    '/web/inventory/:currentTab': Components.WebInventoryPage,
    '/web/network-services/:id': Components.WebNetworkServicesUuidPage,
    '/web/network-services/:id/:currentTab': Components.WebNetworkServicesUuidPage,
    '/web/policies': Components.WebPoliciesPage,
    '/web/policies/:id': '/web/policies/:id/web',
    '/web/policies/:id/:currentTab': Components.WebPoliciesUuidPage,
    '/web/policy-violations': Components.WebPolicyViolationsPage,
    '/web/policy-violations/:id': Components.WebPolicyViolationsUuidPage,
    '/web/security-toolkits': Components.WebSecurityToolkitsPage,
    '/web/security-toolkits/sql-injection/*': Components.WebSecurityToolkitsDetectInjectPage,
    '/web/security-toolkits/xss-attack': Components.WebSecurityToolkitsXssAttackPage,
    '/web/security-toolkits/xss-attack/:currentTab': Components.WebSecurityToolkitsXssAttackPage,
    '/web/security-toolkits/xss-protection': Components.WebSecurityToolkitsXssProtectionPage,
    '/web/security-toolkits/web-protect': Components.WebSecurityToolkitsWebProtectPage,
    '/web/shadow-assets': Components.WebShadowAssetsPage,
    '/web/toxic-tokens/*': Components.WebToxicTokensPage,
    '/web/web-applications/:id': Components.WebApplicationsUuidPage,
    '/web/web-applications/:id/:currentTab': Components.WebApplicationsUuidPage,
};
// prettier-ignore
var managementRoutes = {
    '/management': '/management/products',
    '/management/share/:token/violations/:id': '/management/share/:token/policy-violations/:id',
    '/management/share/:token/policy-violations/:id': Components.ManagementSharePolicyViolationPage,
    '/management/compliance-report': accessControlsApi(Components.ManagementComplianceReports),
    '/management/onboarding': accessControlsApi(Components.ManagementProductOnboardingContainer),
    '/management/products': accessControlsLandingPage(Components.ManagementLandingPage),
    '/management/profile': accessControlsApi(Components.ManagementProfile),
    '/management/share/:token/cloud-resources/:id': accessControlsShare(Components.ShareCloudResourcePublicView),
    '/management/share/:token/cloud-resources/:id/:currentTab': accessControlsShare(Components.ShareCloudResourcePublicView),
    '/management/share/:token/network-services/:id': accessControlsShare(Components.ShareNetworkServicesPublicView),
    '/management/share/:token/network-services/:id/:currentTab': accessControlsShare(Components.ShareNetworkServicesPublicView),
    '/management/share/:token/openscan/apps/:appId': '/management/share/:token/openscan/apps/:appId/metrics',
    '/management/share/:token/openscan/apps/:appId/:currentTab': Components.OpenScanV2AppPublicPage,
    '/management/share/:token/onboard': accessControlsShare(Components.ShareOnboardingPublicView),
    '/management/share/:token/onboarding': accessControlsShare(Components.ShareProductOnboardingWizardPublicView),
    '/management/share/:token/restful-apis/:id': accessControlsShare(Components.ShareRestfulAPIPublicView),
    '/management/share/:token/restful-apis/:id/:currentTab': accessControlsShare(Components.ShareRestfulAPIPublicView),
    '/management/share/:token/web-applications/:id': accessControlsShare(Components.ShareWebAppPublicView),
    '/management/share/:token/web-applications/:id/:currentTab': accessControlsShare(Components.ShareWebAppPublicView),
    '/management/onboarding-wizard': Components.OnboardingWizardPage,
    '/management/onboarding-wizard/:currentStep': Components.OnboardingWizardPage,
    '/management/share/:token/onboarding-wizard': Components.OnboardingWizardPublicViewPage,
    '/management/share/:token/onboarding-wizard/:currentStep': Components.OnboardingWizardPublicViewPage,
    '/management/sdlc/slack-cliq-msft': Components.SlackMicrosoftTeamsIntegrationPage,
    '/management/sdlc/saml': Components.SamlIntegrationPage,
    '/management/sdlc/webhooks': Components.WebhookIntegrationPage,
    '/management/users': userManagementControlsApi(Components.ManagementUserDashboard),
    '/management/users/:userId': userManagementControlsApi(Components.ManagementUserEdit),
    '/management/users/invite': userManagementControlsApi(Components.ManagementUserInvite),
    '/management/activity/:search': Components.ManagementActivity,
    '/management/activity': Components.ManagementActivity,
    '/management/share/:scopedAccessToken/policy-violations': Components.SharedPolicyViolations,
    '/management/share/:scopedAccessToken/policy-violations/details/:id': Components.SharedPolicyViolations,
    '/management/share/v2/:token/sast/:branch/:commitid/issues/:issueid': Components.SastIssuePublicViewPage,
};
// prettier-ignore
var supplyChainRoutes = {
    '/supply-chain': Components.SupplyChainPage,
    '/supply-chain/sbom': Components.SupplyChainSBOMPage,
    '/supply-chain/vendors': Components.SupplyChainVendorsPage,
    '/supply-chain/my-apps': Components.SupplyChainMyAppsPage,
    '/supply-chain/policies': '/supply-chain/policies/headline-hacks',
    '/supply-chain/policies/:currentTab': Components.SupplyChainPolicyPage,
    '/supply-chain/asm-setup': Components.SupplyChainAsmSetupPage,
    '/supply-chain/security-issues': Components.SupplyChainSbomSecurityIssuesPage,
    '/supply-chain/supplier-trust': Components.SupplierTrustPage,
    '/supply-chain/supplier-trust/suppliers/share/:token': '/supply-chain/supplier-trust/suppliers/share/:token/onboarding',
    '/supply-chain/supplier-trust/suppliers/share/:token/:currentTab': Components.VendorUuidPublicViewPage,
    '/supply-chain/supplier-trust/suppliers/:id': '/supply-chain/supplier-trust/suppliers/:id/overview',
    '/supply-chain/supplier-trust/suppliers/:id/:currentTab': Components.VendorUuidPage,
    '/supply-chain/my-apps/web/:id/': Components.SupplyChainAppUuidWebPage,
    '/supply-chain/my-apps/web/:id/:currentTab': Components.SupplyChainAppUuidWebPage,
    '/supply-chain/my-apps/app/:id/': Components.SupplyChainAppUuidMobilePage,
    '/supply-chain/my-apps/app/:id/:currentTab': Components.SupplyChainAppUuidMobilePage,
    // redirect old paths
    '/supply-chain/sbom-security-issues': '/supply-chain/security-issues',
    '/management/scs': '/supply-chain/supplier-trust',
    '/management/scs/vendor/share/:token': '/supply-chain/supplier-trust/suppliers/share/:token',
    '/management/scs/vendor/share/:token/:currentTab': '/supply-chain/supplier-trust/suppliers/share/:token/:currentTab',
    '/management/scs/vendor/:id': '/supply-chain/supplier-trust/suppliers/:id',
    '/management/scs/vendor/:id/:currentTab': '/supply-chain/supplier-trust/suppliers/:id/:currentTab',
};
// prettier-ignore
var mobileSecureRoutes = {
    '/mobile-secure/policies': Components.MobilePoliciesPage,
    '/mobile-secure/policies/:id': '/mobile-secure/policies/:id/mobile',
    '/mobile-secure/policies/:id/:currentTab': Components.MobilePoliciesUuidPage,
    '/mobile-secure/inventory': '/mobile-secure/inventory/mobile-apps',
    '/mobile-secure/inventory/:currentTab': Components.MobileInventoryPage,
    '/mobile-secure/asset-groups/:id': Components.MobileAssetGroupsUuidPage,
    '/mobile-secure/custom-checks': Components.ApiCustomChecksPage,
};
// prettier-ignore
var openScanRoutes = {
    '/openscan/share/:token/app/:appId': '/management/share/:token/openscan/apps/:appId/overview',
    '/openscan/share/:token/app/:appId/overview': '/management/share/:token/openscan/apps/:appId/overview',
    '/openscan/configure': Components.AtlasConfiguration,
    '/openscan/apps/:appId': '/openscan/apps/:appId/metrics',
    '/openscan/apps/:appId/:currentTab': Components.AtlasApplicationView,
    '/openscan': '/openscan/search',
    '/openscan/search': Components.AtlasSearchView,
    '/openscan/search/:appName': Components.AtlasSearchView,
};
// prettier-ignore
var devsecopsRoutes = {
    '/devsecops': Components.DevSecOpsPage,
    '/devsecops/stanford-dish-security-university': Components.StanfordDishUniHome,
    '/devsecops/stanford-dish-security-university/materials': Components.StanfordDishUniViewAllMaterials,
    '/devsecops/integrations/jira': Components.DevSecOpsJiraApiCloudWebLandingPage,
    '/devsecops/integrations/jira/configure': Components.DevSecOpsJiraApiCloudWebConfigure,
    '/devsecops/integrations/jira/configure/:integrationId': Components.DevSecOpsJiraApiCloudWebConfigure,
};
// prettier-ignore
var policiesRoutes = {
    '/policies': Components.PoliciesPage,
    '/policies/:id': '/policies/:id/headline-hacks',
    '/policies/:id/:currentTab': Components.PoliciesUuidPage,
    '/policies/v2/:baseProduct': Components.PoliciesV2Page,
    '/policies/v2/:baseProduct/:id': '/policies/v2/:baseProduct/:id/headline-hacks',
    '/policies/v2/:baseProduct/:id/:currentTab': Components.PoliciesV2UuidPage,
    '/policies/v2/openscan': Components.PoliciesOpenscanRedirectPage,
};
var routesPageContent = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ '/mobile': '/mobile-secure/v2', '/dashboard': Components.DashboardPage }, settingsV2Routes), apiRoutes), webRoutes), cloudRoutes), mobileSecureRoutes), devsecopsRoutes), openScanRoutes), managementRoutes), supplyChainRoutes), apiV2Routes), devsecopsV2Routes), mobileV2Routes), webV2Routes), cloudV2Routes), openScanV2Routes), policiesRoutes);
export default routesPageContent;
